import { ethers } from 'ethers';
import NFT_ABI from '../ABI/NFT.json';
import getDefaultProvider from './getDefaultProvider';
import getSigner from './getSigner.js';


const getNftInstance = async (address,readOnly = false) => {
    if(readOnly){
    const provider = await getDefaultProvider()
        return new ethers.Contract(address, NFT_ABI, provider)
    }
    const signer = await getSigner(true)
    if (!signer) return
    return new ethers.Contract(address, NFT_ABI, signer);
}
export default getNftInstance;
