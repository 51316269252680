import { ethers } from 'ethers';
import getSigner from './getSigner.js';
import getDefaultProvider from './getDefaultProvider';
import OS_CANCEL_ABI  from '@contract/ABI/OS_CANCEL.json'
import { TRADE_OP_MAIN_ADDRESS, TRADE_OP_V1_MAIN_ADDRESS,TRADE_OP_V14_MAIN_ADDRESS } from '@contract/Address'

const ADDRESS_MAP ={
   [TRADE_OP_V1_MAIN_ADDRESS?.toLocaleLowerCase()] : TRADE_OP_V1_MAIN_ADDRESS,
   [TRADE_OP_V14_MAIN_ADDRESS?.toLocaleLowerCase()] : TRADE_OP_V14_MAIN_ADDRESS,
}

const get_OS_Instance = async (address, readOnly = false) => {
    const provider = await getDefaultProvider()
     //有些同步过来的订单可能是旧版本的，所以需要用旧版的合约去同步
    const _address = ADDRESS_MAP[address?.toLocaleLowerCase()] || TRADE_OP_MAIN_ADDRESS

    if (!readOnly) {
        const signer = await getSigner(true)
        if (!signer) return
        return new ethers.Contract(_address, OS_CANCEL_ABI, signer);
    }
    return new ethers.Contract(_address, OS_CANCEL_ABI, provider)
}
export default get_OS_Instance;
