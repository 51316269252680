import { omit } from 'lodash-es'
import { post, get } from '@utils/axios'

const getPath = (chainId) => {
  const chain = chainId || localStorage?.getItem('chain')

  return (!chain || chain === 1 || chain === '1') ? 'v2/' : `multi-chain/${chain}/`
}

// 后端类型枚举 (1:Buy,3:List,6.Offer,7.Sell)
export const saveOrder = params => post(`/api/${getPath(params?.chainId)}trade/order/create`, omit(params, ['chainId']))

export const getOrder = params => post(`/api/${getPath(params?.chainId)}trade/order`, omit(params, ['chainId']))

export const postLowerPrice = params => post(`/api/${getPath(params?.chainId)}trade/order/lower-price`, omit(params, ['chainId']))

export const postIsOwner = params => post(`/api/${getPath(params?.chainId)}item/owner`, omit(params, ['chainId']))

// 聚合交易接口
export const saveTradeOrder = params => post('/api/v2/trade/orders', params)
export const getFees = (address, params) => get(`/api/v2/collection/${address}/creator-fee`, params)
export const get_LK_Nonce = params => get('/api/v2/trade/orders/nonce', params)
export const get_XY_cancel_input = params => post('/api/v2/trade/orders/cancel', params)
export const get_XY_order_sign = params => post('/api/v2/trade/orders/sign', params)
export const getListStatus = (params, address) => get(`/api/v2/item/${params.colAddress}/${params.tokenId}/listing`, address)
export const getAggListings = (params, contract_address, tokenId) => get(`/api/v2/item/${contract_address}/${tokenId}/agg-listings`, params)
export const getTopTrait = params => get(`/api/v2/item/${params.colAddress}/${params.tokenId}/top-trait`)
export const getPendingTxs = params => post('/api/v2/trade/pending-txs', params)
export const getListFee = params => post('/api/v2/collection/listing/fee', params)