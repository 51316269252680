import React, { useState } from 'react'
import { Button } from '@mui/material'
import { subscribeEmail } from '@/api'
import config from '@/config/config'
import styles from '@/assets/Footer.module.scss'
import { showSnackBar } from '@/components/CustomizedSnackbars'

const assetsUrl = config.assetsUrl
const logoUrl = `${assetsUrl}0.3.3/`

const footerIcons = [
  {
    name: 'twitter_normal@3x',
    hover_name: 'twitter_hover@3x',
    url: 'https://twitter.com/Nswapcom',
  },
  {
    name: 'medium_normal@3x',
    hover_name: 'medium_hover@3x',
    url: 'https://medium.com/nswap',
  },
  {
    name: 'discord_normal@3x',
    hover_name: 'discord_hover@3x',
    url: 'https://discord.gg/nswap',
  },
  {
    name: 'telegram_normal@3x',
    hover_name: 'telegram_hover@3x',
    url: 'https://t.me/nswapdao',
  },
  {
    name: 'instagram_normal@3x',
    hover_name: 'instagram_hover@3x',
    url: 'https://www.instagram.com/nswapcom',
  },
  {
    name: 'youtube_normal@3x',
    hover_name: 'youtube_hover@3x',
    url: 'https://www.youtube.com/channel/UCabA1970bVEzI0G5ZbGanaQ',
  },
]

const Footer = () => {
  const [email, setEmail] = useState('')
  const submitSubscribe = async() => {
    const response = await subscribeEmail({
      email,
    })

    if (response?.code === 200) {
      showSnackBar({ type: 'success', message: 'Email subscription success!' })
    } else {
      showSnackBar({ type: 'error', message: 'Email subscription failed!' })
    }
  }

  const hadnleSubscribeEmail = () => {
    const gwEmail =
      /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{1,100}$/ // 非空验证

    if (email === '') {
      showSnackBar({ type: 'error', message: 'Please input the email address!' })
    } else if (!gwEmail.test(email)) {
      showSnackBar({ type: 'error', message: 'Email format is incorrect!' })
    } else {
      submitSubscribe()
    }
  }

  const handleInputEmail = (e) => {
    const { value } = e.target
    setEmail(value)
  }

  return (
    <footer className={styles.footer_container}>
      <div className={styles.footer_box}>
        <div className={styles.footer_leftBox}>
          <div className={styles.title}>
            Keep in Touch
          </div>
          <div className={styles.desc}>
            We encourage every Nswappers to join this mailing list to seek help
            and keep in touch with our hottest NFTs, raffles, and airdrops from now on.
          </div>
          <div className={styles.input_container}>
            <div className={styles.email}>
              <input type="email" placeholder="Your email address" onChange={handleInputEmail}/>
            </div>
            <Button
              variant="contained"
              className={styles.subscribe}
              onClick={hadnleSubscribeEmail}
            >
              Sign up
            </Button>
          </div>
        </div>
        <div className={styles.footer_rightBox}>
          <div className={styles.iconTitle}>
            Join the community
          </div>
          <div className={styles.icons_container}>
            <div className={styles.icons}>
              {footerIcons.map((item) => {
                return (
                  <div className={styles.media_icon} key={item.url}>
                    <a href={item.url} target="_blank" rel="noreferrer">
                      <img className={styles.img_common} src={`${logoUrl}${item.name}.png`} alt={item.name}/>
                      <img className={styles.img_hover} src={`${logoUrl}${item.hover_name}.png`} alt={item.name}/>
                    </a>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>

    </footer>
  )
}

export default Footer
