import { ethers } from 'ethers';
import { WETH_ADDRESS, WETH_MAIN_ADDRESS } from '../Address.js'
import DAI_ABI from '../ABI/DAI.json';
import getSigner from './getSigner.js';
// import { getEnv } from '@utils/utils.js';
// import { EnvType } from '@utils/constant.js';
import { isProduction } from '@utils/utils'


const getWethInstance = async () => {
    // const env = getEnv()
    const address = isProduction() ? WETH_MAIN_ADDRESS : WETH_ADDRESS
    const signer = await getSigner(true)
    if (!signer) return
    return new ethers.Contract(address, DAI_ABI, signer);
}
export default getWethInstance;
