import React, { useEffect } from 'react'
import Select from '@/components/Select'
import { useRouter } from 'next/router'
import styles from '@/assets/Header.module.scss'
import { NET_WORKS_IDS } from '@/utils/networks.js'
import { useSelector, useDispatch } from 'react-redux'
import { setVisitChain } from '@/slices/chainSlice'
import { getMainChainOptions } from './constants'

const needChangeUrlPaths = ['/', '/eth', '/ethw', '/bsc', '/ipo']

export default function ChainSelect({
  isPhone,
}) {
  const { visitChain } = useSelector(state => state.chain)
  const dispatch = useDispatch()
  const router = useRouter()

  useEffect(() => {
    let _chain = NET_WORKS_IDS.ETH

    if (needChangeUrlPaths.includes(router?.asPath) ) {
      if (router?.asPath === '/eth') {
        _chain = NET_WORKS_IDS.ETH
      }
      if (router?.asPath === '/bsc') {
        _chain = NET_WORKS_IDS.BSC
      }
      if (router?.asPath === '/ethw') {
        _chain = NET_WORKS_IDS.ETHW
      }
      if (router?.asPath === '/ipo') {
        _chain = NET_WORKS_IDS.IPO
      }
    } else {
      const storeChain = localStorage.getItem('chain')
      _chain = storeChain === 'undefined' ? NET_WORKS_IDS.ETH : storeChain
    }

    dispatch(setVisitChain(Number(_chain)))
  }, [router])

  const handleChainChange = (item) => {
    const { value, label } = item || {}

    dispatch(setVisitChain(value))

    //如果当前是在首页，点击需要切换路由
    if (needChangeUrlPaths.includes(router?.asPath) ) {
      router.push(`/${label.toLocaleLowerCase()}`)
    }
  }

  return (
    <Select
      header={
        isPhone ? (
          <span>
            <img
              src={visitChain?.url}
              width={32}
              height={32}
              style={{ marginRight: '3rem', position: 'relative', top: '2px' }}
            />
          </span>
        )
          : (
            <div className={styles.chainName} >
              <img src={visitChain?.url} width={24} height={24} style={{ marginRight: 8 }}/>
              {visitChain?.chainName}
            </div>
          )
      }
      defaultValue={visitChain?.chainId}
      defaultLabel={visitChain?.chainName}
      chain={true}
      options={getMainChainOptions()}
      onChange={handleChainChange}
    />
  )
}
