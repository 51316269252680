import { ethers } from 'ethers';
import getSigner from './getSigner.js';
import getDefaultProvider from './getDefaultProvider';
import TRADE_ABI from '@contract/ABI/TRADE.json'
import { TRADE_AMOUNT_MAIN_ADDRESS } from '@contract/Address'

const get_Trade_Instance = async (readOnly = false) => {
    const provider = await getDefaultProvider()
    if (!readOnly) {
        const signer = await getSigner(true)
        if (!signer) return
        return new ethers.Contract(TRADE_AMOUNT_MAIN_ADDRESS, TRADE_ABI, signer);
    }
    return new ethers.Contract(TRADE_AMOUNT_MAIN_ADDRESS, TRADE_ABI, provider)
}
export default get_Trade_Instance;
