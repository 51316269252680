// import web3Modal from "../../utils/web3Modal"
// import { ethers } from 'ethers'
// import { errorCaptured } from '@contract/utils'
// import isMobile from 'is-mobile'
import { fetchSigner } from '@wagmi/core';


const getSigner = async (flag) => {
  // if(isMobile() && !flag)return
  // const [err, instance] = await errorCaptured(web3Modal.connect)
  // if (err) {
  //   console.log(err?.message, 'error')
  //   return
  // }
  // const provider = new ethers.providers.Web3Provider(instance)
  // if (!provider) {
  //   console.error("please connect a wallet first");
  //   return;
  // }
  // return provider.getSigner()
  const wagmiSigner = await fetchSigner();
  if (!wagmiSigner) {
    console.error('please connect a wallet first')
    return
  }
  return wagmiSigner
}

export default getSigner
