import {  ethers } from 'ethers'
import { Types } from "./TEST/order"
import BigNumber from 'bignumber.js'
import { getNetwork } from '@wagmi/core'

import { getEnv } from '@/utils/utils'
import { EnvType } from '@utils/constant'
import { isProduction } from '@utils/utils'
import { TRADE_OP_MAIN_ADDRESS } from '@contract/Address'
import OS_TYPE from '@contract/TEST/os_type'

export async function getSignature(order, signer, address) {
  const { chain } = getNetwork()
  return sign(order, chain?.id, signer, address)
}

async function sign(order, chainId, account, verifyingContract) {
  // 这个地方任何信息不能动，会影响签名
  return account._signTypedData({
    name: "Nswap",
    version: "1",
    chainId,
    verifyingContract,
  }, Types, order)
}


export async function errorCaptured(asyncFunc) {
  if (typeof asyncFunc !== 'function') {
    throw new TypeError('Error')
  }
  try {
    let arg = [...arguments].slice(1)
    let res = await asyncFunc(...arg)
    return [null, res]
  } catch (e) {
    return [e, null]
  }
}

export async function errorCapturedTwo(obj, fucName) {
  if (typeof obj[fucName] !== 'function') {
    throw new TypeError('Error')
  }
  try {
    let arg = [...arguments].slice(2)
    let res = await obj[fucName](...arg);
    return [null, res]
  } catch (e) {
    return [e, null]
  }
}

export function transBigNumber(object) {
  let isBig = ethers.BigNumber.isBigNumber(object);
  console.log(isBig, 'isBigNumber')
  if (isBig) {
    return object.toString();
  }
  return object;
}


export async function executeContractMethodWithEstimatedGas(contract, functionName, args) {
  const minimumGasValue = await contract.estimateGas[functionName](...args).then((value) => {
    console.log(value, 'value-----')
    return value
  })
    .catch((err) => {
      console.log(err, 'EstimatedGas')
      return ethers.BigNumber.from("250000")
    })
  console.log(minimumGasValue, 'minimumGasValue')
  const estimatedGas = new BigNumber(ethersOf(minimumGasValue))
  const argsForOverridden = args.pop()
  argsForOverridden.gasLimit = parseEthers(estimatedGas.times(1.1).toString())
  args.push(argsForOverridden)
  return contract[functionName](...args)
}


const ethersOf = (amount) => {
  return ethers.utils.formatEther(amount)
}

const parseUnits = (amount, unit) => {
  const bnAmount = new BigNumber(amount)
  try {
    return ethers.utils.parseUnits(bnAmount.toFixed(unit), unit)
  } catch (e) {
    return ethers.BigNumber.from(bnAmount.times(Math.pow(10, unit)).toFixed(0))
  }
}

const parseEthers = (amount) => {
  return parseUnits(amount, 18)
}

export async function switchToEthereum() {
  const chainSymbol = {
    1: '0x1',
    10001: '0x2711',
    56: '0x38'
  }
  
  const env = getEnv()
  console.log(env, EnvType.PRODUCTION, 'env-----')
  if (isProduction()) {
    try {
      const chain = localStorage?.getItem('chain')
      const p = window.ethereum && window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: chainSymbol[chain] || '0x1'
          }
        ]
      })
      if(p) {
        return p.then(() => {
          return true
        }).catch(() => false)
      } else {
        return false
      }
    } catch (error) {
      console.log(error);
      return false
    }
  }
  return true
}

//切换到某个链
export async function switchToChain(chain) {
  const chainSymbol = {
    1: '0x1',
    10001: '0x2711',
    56: '0x38'
  }
  
  const env = getEnv()
  console.log(env, EnvType.PRODUCTION, 'env-----')
  if (isProduction()) {
    try {
      const p = window.ethereum && window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: chainSymbol[chain] || '0x1'
          }
        ]
      })
      if(p) {
        return p.then(() => {
          return true
        }).catch(() => false)
      } else {
        return false
      }
    } catch (error) {
      console.log(error);
      return false
    }
  }
  return true
}

export const sign_OS_Order = (order, signer) => {

  return signer._signTypedData({
    name: "Seaport",
    version: "1.5",
    chainId: 1,
    verifyingContract: TRADE_OP_MAIN_ADDRESS,
  }, OS_TYPE, order)
}