import { defaultAbiCoder } from 'ethers/lib/utils'

export const SEAPORT = 0x10
export const SWEEP = 0x04
const NFTX = 0x12
const LOOKS_RARE_721 = 0x11
const LOOKS_RARE_1155 = 0x14
const X2Y2_721 = 0x18
const X2Y2_1155 = 0x1b
const FOUNDATION = 0x1c
const SUDOSWAP = 0x19
const NFT20 = 0x1a
const ALLOW_REVERT_FLAG = 0x80

const ABI_DEFINITION = {
  [SWEEP]: ['address', 'address', 'uint256'],
  [SEAPORT]: ['uint256', 'bytes'],
}

const REVERTABLE_COMMANDS = new Set([
  SEAPORT,
  NFTX,
  LOOKS_RARE_721,
  LOOKS_RARE_1155,
  X2Y2_721,
  X2Y2_1155,
  FOUNDATION,
  SUDOSWAP,
  NFT20,
])
export class RoutePlanner {
  constructor() {
    this.commands = '0x'
    this.inputs = []
  }

  addCommand(type, parameters, allowRevert = false) {
    let command = createCommand(type, parameters)
    this.inputs.push(command.encodedInput)
    if (allowRevert) {
      if (!REVERTABLE_COMMANDS.has(command.type)) {
        throw new Error(`command type: ${command.type} cannot be allowed to revert`)
      }
      command.type = command.type | ALLOW_REVERT_FLAG
    }

    console.log(command.type, command.type.toString(16).padStart(2, '0'), 'command.type---')

    this.commands = this.commands.concat(command.type.toString(16).padStart(2, '0'))
  }
}

export function createCommand(type, parameters) {
  console.log(type, parameters, 'type---')
  const encodedInput = defaultAbiCoder.encode(ABI_DEFINITION[type], parameters)
  return { type, encodedInput }
}