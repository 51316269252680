import { useState } from 'react'
import classNames from 'classnames'
import { Tabs, Tab, MenuItem, Paper, MenuList, Popper } from '@mui/material'
import Router from 'next/router'
import styles from '@/assets/Tabs.module.scss'
import { showSnackBar } from '../CustomizedSnackbars'
import { HOT_TIPS } from '@utils/img'
import { useAccount } from 'wagmi'
import useConnect from '@/hooks/useConnect'

const navList = [
  {
    key: 0,
    label: 'Launchpad',
    value: 'launchpad',
  },
  {
    key: 1,
    label: 'Lucky',
    value: 'raffles',
  },
  {
    key: 2,
    label: 'Rankings',
    value: 'rankings',
  },
  // {
  //   key: 3,
  //   label: 'Community',
  //   value: 'community',
  // },
  {
    key: 3,
    label: 'Explore',
    value: 'explore',
  },
]

export default function BasicTabs(props) {
  const { value, match, onTabClick, onTabChange } = props
  const [anchorEl, setAnchorEl] = useState(null)
  const [open, setOpen] = useState(false)
  const [anchorElCol, setAnchorElCol] = useState(null)
  const [openCol, setOpenCol] = useState(false)
  const { isConnected } = useAccount()
  const { connect } = useConnect()

  let timeout = null
  let timeoutCol = null
  const duration = 200

  const handleMenu = (event) => {
    if (!!event) {
      setAnchorEl(event.currentTarget)
    }
    setOpen(true)
  }
  const handleMenuCol = (event) => {
    if (!!event) {
      setAnchorElCol(event.currentTarget)
    }
    setOpenCol(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleCloseCol = () => {
    setOpenCol(false)
  }

  const tabMouseEnter = (e, tab) => {
    if (tab === 'raffles') {
      clearTimeout(timeout)
      handleMenu(e)
    } else if (tab === 'rankings') {
      clearTimeout(timeoutCol)
      handleMenuCol(e)
    } else {
      handleClose()
      handleCloseCol()
    }
  }

  const tabMouseLeave = (tab) => {
    if (tab === 'raffles') {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        handleClose()
      }, duration)
    } else if (tab === 'rankings') {
      clearTimeout(timeoutCol)
      timeoutCol = setTimeout(() => {
        handleCloseCol()
      }, duration)
    }
  }

  const handleRedirect = (value) => {
    onTabClick && onTabClick(false)
    onTabChange && onTabChange(value)
    if (value === 'community') {
      const address = localStorage.getItem('address')
      if (address === 'null') {
        showSnackBar({ type: 'error', message: 'Please sign in.' })
      } else {
        window.open(`https://bbs.nswap.com/User/Login?address=${address}`)
      }
    } else {
      Router.push(`/${value}`)
    }
  }

  return !match ? (
    <div className={styles.column_tab}>
      <div className={styles.mobile_column_tab}>
        {navList.map((item) => {
          return (
            <button key={item.key} onClick={() => handleRedirect(`${item.value}`)} style={{ position: 'relative' }}>
              {item.label}
            </button>
          )
        })}
      </div>
    </div>
  ) : (
    <>
      <Tabs
        value={value}
        sx={{
          '& .MuiTabs-indicator': {
            display: 'none',
          },
          '& .MuiButtonBase-root': {
            color: 'rgba(0, 0, 0, 0.6)',
            fontWeight: '600',
            opacity: 1,
            '&.Mui-selected': {
              color: '#000',
              opacity: 1,
            },
            '&:hover': {
              color: '#000',
              opacity: 1,
            },
          },
        }}
      >
        <img style={{ position: 'absolute', left: 414 }} src={HOT_TIPS} width={36} height={16}/>

        {navList.map((item) => {
          return (
            <Tab
              // sx={{
              //   '&.Mui-selected': {
              //     color: 'rgba(0, 0, 0, 0.6)!important',
              //   },
              // }}
              {...item}
              key={item.key}
              aria-owns={open ? 'menu-appbar' : undefined}
              aria-haspopup="true"
              className={classNames(styles.tab, styles.tab_font)}
              onClick={() => handleRedirect(`${item.value}`)}
              onMouseEnter={e => tabMouseEnter(e, item.value)}
              onMouseLeave={() => tabMouseLeave(item.value)}
            />
          )
        })}
      </Tabs>
      <Popper
        open={open}
        anchorEl={anchorEl}
        id="menu-appbar"
        keepMounted={false}
        onMouseEnter={() => {
          clearTimeout(timeout)
          handleMenu()
        }}
        onMouseLeave={() => {
          clearTimeout(timeout)
          timeout = setTimeout(() => {
            setOpen(false)
          }, duration)
        }}
        className={styles.tabs_select}
        style={{
          zIndex: 999,
          width: 240,
          overflow: 'hidden',
          background: '#fff',
          border: '0.5px solid #dcdcdc',
          borderRadius: '12px',
          boxShadow: '0px 2px 16px 0px rgba(0,0,0,0.09)',
        }}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [20, 10],
            },
          },
        ]}
      >
        <Paper sx={{
          '& .MuiList-root': {
            padding: 0,
          },
          '& .MuiMenuItem-root': {
            fontSize: '16px',
            height: '54px',
            fontWeight: 500,
            color: '#666',
          },
        }}
        >
          <MenuList>
            <MenuItem onClick={() => {
              setOpen(false)
              Router.push('/raffles')
            }}
            >All Lucky
            </MenuItem>
            <MenuItem onClick={async () => {
              setOpen(false)
              if (isConnected) {
                Router.push('/raffles/create')
              } else {
                connect()
              }
            }}
            >Create Lucky
            </MenuItem>
          </MenuList>
        </Paper>
      </Popper>
      {/* <Popper
        open={openCol}
        anchorEl={anchorElCol}
        id="menu-appbar"
        keepMounted={false}
        onMouseEnter={() => {
          clearTimeout(timeoutCol)
          handleMenuCol()
        }}
        onMouseLeave={() => {
          clearTimeout(timeoutCol)
          timeoutCol = setTimeout(() => {
            setOpenCol(false)
          }, duration)
        }}
        className={styles.tabs_select}
        style={{
          zIndex: 999,
          width: 240,
          overflow: 'hidden',
          background: '#fff',
          border: '0.5px solid #dcdcdc',
          borderRadius: '12px',
          boxShadow: '0px 2px 16px 0px rgba(0,0,0,0.09)',
        }}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [20, 10],
            },
          },
        ]}
      >
        <Paper sx={{
          '& .MuiList-root': {
            padding: 0,
          },
          '& .MuiMenuItem-root': {
            fontSize: '16px',
            height: '54px',
            fontWeight: 500,
            color: '#666',
          },
        }}
        >
          <MenuList>
            <MenuItem onClick={async () => {
              setOpenCol(false)
              Router.push('/rankings')
            }}
            >Rankings
            </MenuItem>
            <MenuItem onClick={() => {
              setOpenCol(false)
              Router.push('/market')
            }}
            >Market
            </MenuItem>
          </MenuList>
        </Paper>
      </Popper> */}
    </>
  )
}
