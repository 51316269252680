import { omit } from 'lodash-es'
import { get, post, deleteAlias } from '@/utils/axios'

const getPath = (chainId) => {
  const chain = chainId || localStorage?.getItem('chain')

  return (!chain || chain === 1 || chain === '1') ? 'v2/' : `multi-chain/${chain}/`
}

const itemSearchConfig = {
  items: {
    page: 1,
    page_size: 3,
  },
  collections: {
    page: 1,
    page_size: 3,
  },
  users: {
    page: 1,
    page_size: 3,
  },
}

// get Projec tList
export const getProjectList = params => post('api/v1/project/list', params)
export const getFeaturedList = params => post('api/v2/project/featured', params)
export const getAllProject = params => post('api/v2/project/all', params)
//get Project Detail
export const getProjectDetail = params => get(`/api/v2/project/infocache/${params?.project_id}`)

// get proof
export const getProof = params => post('/api/v2/project/proof', params)

//get HomePage Project List
export const getHomePageProjectList = params => get('api/v1/project/trending', params)

// get HomePage  v1/banner/list
export const getBannerList = params => get('/api/v2/banner/list', params)

//get Corporation Detail
export const getCorporationDetail = params => get('api/v1/corporation/info', params)

// item detail
export const getItemDetail = params => get(`/api/${getPath(params?.chainId)}item/${params?.contract}/${params?.tokenId}/detail`, {})
export const getDollar = params => get(`/api/${getPath(params?.chainId)}price`, omit(params, ['chainId']))
export const getRanks = params => get(`/api/v2/item/${params?.contract}/${params?.tokenId}/rarity`, params)
export const getItemAttr = params => post(`/api/${getPath(params?.chainId)}item/attr`, omit(params, ['chainId']))
export const itemRefresh = params => post(`/api/${getPath(params?.chainId)}item/refresh`, omit(params, ['chainId']))
export const getItemInfo = params => get(`/api/${getPath(params?.chainId)}item/${params?.contract}/${params?.tokenId}/info`, {})
//subscribe email
export const subscribeEmail = params => post('/api/v1/subscribe/email', params)

// search
export const getSearchList = params =>
  post(`/api/${getPath(params?.chainId)}search/list`, Object.assign({}, omit(params, ['chainId']), itemSearchConfig))

// login
export const login = params => get('/api/v2/user/login', params)

export const info = params => get('/api/v2/user/info', params)

// user collected
export const getUserCollected = params => post(`/api/${getPath(params?.chainId)}user/collection`, omit(params, ['chainId']))

// user like
export const getUserLike = params => post(`/api/${getPath(params?.chainId)}user/follow`, omit(params, ['chainId']))

// remove user like
export const removeUserLike = params => post(`/api/${getPath(params?.chainId)}collection/follow/remove`, omit(params, ['chainId']))

// user offer
export const getOffer = params => post(`/api/${getPath(params?.chainId)}offer`, omit(params, ['chainId']))

// activity
export const getActivity = params => post(`/api/${getPath(params?.chainId)}activity`, omit(params, ['chainId']))

// get user Info
export const getUserInfo = params => get('/api/v2/user/info', params)

// update user info
export const updateUserInfo = params => post('/api/v2/user/update', params)

// upload
export const uploadFile = params => post(`/api/v2/file/upload?file_type=${params.file_type}&creator=${params.creator}`, params.data)

// collection imported
export const checkCollectionImported = params => post('/api/v2/collection/imported', params)

export const checkCollectionImportedBSC = params => post('/api/multi-chain/56/collection/imported', params)

// item
export const getItemList = params => post(`/api/${getPath(params?.chainId)}item/list`, omit(params, ['chainId']))
// item(ethw)
export const getETHWItemList = params => post('/api/multi-chain/10001/item/list', params)
// item(eth)
export const getETHItemList = params => post('/api/v2/item/list', params)
// collection serach
export const getSearchCollectionList = params => post(`/api/${getPath(params?.chainId)}collection/search`, omit(params, ['chainId']))

// import collection
export const importCollection = params => post(`/api/${getPath(params?.chain)}import/collection`, params)

// collection info
export const getCollectionInfo = params => get(`/api/${getPath(params?.chainId)}collection/${params.collection_address}/info`)

// check user like collection
export const checkUserLike = params => post(`/api/${getPath(params?.chainId)}follow/check`, omit(params, ['chainId']))

// like collection
export const updateUserLike = params => post(`/api/${getPath(params?.chainId)}collection/follow`, omit(params, ['chainId']))

// collection attributes
export const getCollectionAttributes = params => post(`/api/${getPath(params?.chainId)}item/attributes`, omit(params, ['chainId']))

// collection items
export const getCollectionItems = params => post(`/api/${getPath(params?.chainId)}collection/items`, omit(params, ['chainId']))

// rarity
export const getRarity = params => post('/api/v2/item/rarity', params)

// create project
export const createProject = params => post('/api/v2/project/add', params)

// edit project
export const editProject = params => post('/api/v2/project/update', params)

// raffle detail
export const getRaffleDetailItem = params => get(`/api/v2/raffles/${params.raffle_id}`)

// get raffle user
export const getRaffleUser = params => get(`/api/v2/raffles/${params.raffle_id}/users`, params.data)

// set raffle user status
export const setRaffleUserStatus = params => post(`/api/v2/raffles/${params.raffle_id}/users`, params.data)

// add raffle limit user
export const addRaffleLimitUser = params => post(`/api/v2/raffles/${params.raffle_id}/blacklist`, params.data)

// delete raffle limit user
export const deleteRaffleLimitUser = params => deleteAlias(`/api/v2/raffles/${params.raffle_id}/blacklist`, params.data)

// post lottery
export const postLottery = params => post(`/api/v2/raffles/${params.raffle_id}/winners`)
// user project
export const getUserProject = params => get('/api/v2/user/projects', params)

// create raffle
export const createRaffle = params => post('/api/v2/raffles', params)

// edit raffle
export const editRaffle = params => post(`/api/v2/raffles/${params.raffle_id}`, params)

// raffle detail
export const getRaffleDetail = params => get(`/api/v2/raffles/${params.id}`)

// user raffle
export const getUserRaffle = params => get('/api/v2/user/raffles', params)

export const getRaffle = params => get('/api/v2/raffles', params)
export const getMyRaffle = params => get('/api/v2/user/raffles', params)

export const metrics = (params) => {
  post('/api/v1/metrics/views', params)
}

// raffle detail info
export const getRaffleDetailInfo = id => get(`/api/v2/raffles/${id}`)

// raffle update
export const updateRaffleInfo = (id, params) => post(`/api/v2/raffles/${id}`, params)

// twitter request token
export const getTwitterReqToken = () => post('/api/v2/auth/twitter/request_token', { callback: `${location.origin}/` })

// twitter/discord auth
export const storageAuthInfo = (type, address, params) => post(`/api/v2/auth/${type}/${address}`, params)

// twitter/discord info
export const getAuthInfo = address => get(`/api/v2/auth/${address}`)

// share invite code
export const getShareCode = address => get(`/api/v2/user/${address}/invite-code`)

// raffle register
export const registerRaffle = (address, params) => post(`/api/v2/user/${address}/raffle`, params)

// remove twitter/discord
export const removeMedia = (type, address) => deleteAlias(`/api/v2/auth/${type}/${address}`)

// Trending NFT list
export const getTrendingNftList = params => get('/api/v2/collection/trending', params)

export const updateColInfo = params => post(`/api/${getPath(params?.chainId)}collection/update`, omit(params, ['chainId']))

export const getIsNewUser = params => get('/api/v2/user/registered', params)

export const createInviteUser = params => post('/api/v2/user/invite/create', params)

// snaili
export const getSnailiList = (params) => {
  const { address, page, page_size } = params
  return get(`/api/multi-chain/10001/user/${address}/items?page=${page}&page_size=${page_size}`)
}

// crazy snaili & badge
export const getCrazySnailiData = (params) => {
  const { address, type, page, page_size } = params
  return get(`api/multi-chain/10001/user/${address}/${type}?page=${page}&page_size=${page_size}`)
}

// homepage hot
export const getHotList = () => get('/api/v2/banner/hot')

export const getCartItems = (params) => {
  const { chain, orderIds } = params || {}
  return get(`/api/${chain === 1 ? 'v2/' : `multi-chain/${chain}/`}item/cart?${orderIds}`)
}

//购物车专用
export const getValidOrders = (params) => {
  const { chain, order_ids } = params || {}
  return post(`/api/${chain === 1 ? 'v2/' : `multi-chain/${chain}/`}trade/order/batch`, { order_ids })
}

export const getLeggi = (params) => {
  return post('/api/multi-chain/56/user/leggi', params)
}

export const getLeggiSignature = (params) => {
  const { chain, ...rest } = params
  return post(`/api/multi-chain/${chain}/user/signature`, rest)
}

export const getLucciOrLeggi = (address, params) => {
  return get(`/api/multi-chain/56/user/${address}/items`, params)
}

export const getPartsTotal = (address, params) => {
  return get(`/api/multi-chain/56/user/${address}/attribute-statistics`, params)
}

export const getCampaignInfo = (chain_id = 56, mint_id) => {
  return get(`/api/multi-chain/${chain_id}/mint/${mint_id}/campaign`)
}

export const createSig = (chain_id = 56, mint_id) => {
  return post(`/api/multi-chain/${chain_id}/mint/${mint_id}/sig`)
}

export const getTwitterToken = () => post('/api/v2/auth/twitter/request_token', { callback: `${location.origin}/` })

export const getCampaignList = params => post('/api/v2/project/campaign', params)

export const getBscAnnualReview = (address) => {
  return post(`/api/multi-chain/56/user/${address}/annual-review`)
}

export const getEthAnnualReview = (address) => {
  return post(`/api/v2/user/${address}/annual-review`)
}

export const getAnnualMintSign = () => {
  return get('/api/multi-chain/1/mint/annual')
}

export const createAnnualMintSign = () => {
  return post('/api/multi-chain/1/mint/annual')
}
// 折线图数据

export const getChartData = (params) => {
  return post('api/v2/collection/chart', params)
}