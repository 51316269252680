import MINT_ABI from './MINT.json'
import MINT_ABI_TWO from './MINT2.json'
import Third_ABI from './OtherMINT.json'
import IPOLOO_ABI from './IPOLOO.json'
import PEAKBOY_ABI from './PEAKBOY.json'
import ACTIVITY_ABI from './ACTIVITY.json'
import MINT_7140_ABI from './MINT_7140.json'
import Elephant_ABI from './Elephant.json'
import OOC_ABI from './OOC.json'
import TRADE from './TRADE.json'
import OS_CANCEL_ABI from './OS_CANCEL.json'

export default {
  MINT_ABI,
  MINT_ABI_TWO,
  Third_ABI,
  IPOLOO_ABI,
  PEAKBOY_ABI,
  ACTIVITY_ABI,
  MINT_7140_ABI,
  Elephant_ABI,
  OOC_ABI,
  TRADE,
  OS_CANCEL_ABI
}