import React from 'react'
import { useRouter } from 'next/router'
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  Drawer,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { toNumber } from 'lodash-es'

import Price from '../Price'

import { useAppContext } from '@/utils/context'
import { imgOnError } from '@/utils/utils'
import styles from '@/assets/cart/CartFeedback.module.scss'

const CartFeedback = ({
  currentCart,
}) => {
  const router = useRouter()
  const {
    state: { cartState },
    cartDispatch,
  } = useAppContext()

  const totalPrice = cartState?.success?.reduce((prev, curr) => toNumber(prev) + toNumber(curr.list_price), 0)
  const matches = useMediaQuery('(max-width:750px)')

  const handleClose = () => {
    cartDispatch({ type: 'feedback', payload: false })
  }

  const handleToMySpace = () => {
    cartDispatch({ type: 'feedback', payload: false })
    router.push('/user')
  }

  return (
    <>
      {!matches ? (
        <Dialog
          open={cartState?.feedback}
          onClose={handleClose}
          sx={{
            '& .MuiDialog-container': {
              '& .MuiPaper-root': {
                minWidth: '550px',
                maxWidth: '800px',
                maxHeight: '600px',
                margin: '25px',
                borderRadius: '10px',
              },
            },
          }}
        >
          <DialogTitle sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            py: '24px',
            fontSize: '20px',
            lineHeight: '30px',
            fontWeight: 600,
          }}
          >
            You did it!
            <CloseIcon sx={{ position: 'absolute', right: '24px', cursor: 'pointer' }} onClick={handleClose} />
          </DialogTitle>

          <DialogContent dividers="paper" sx={{ padding: '25px 0 0', overflow: 'hidden' }}>
            <DialogContentText>
              <Box className={styles.successContainer}>
                {cartState?.success?.length > 0 && (
                  <Box className={styles.success}>
                    <Box className={styles.title}>
                      <Typography
                        variant="h1"
                        component="h1"
                      >
                        Success {cartState?.success?.length ? `(${cartState?.success.length})` : ''}
                      </Typography>
                    </Box>
                    <Box className={styles.itemContainer}>
                      {cartState?.success?.map(item => (
                        <Box key={item.id} className={styles.item}>
                          <Box className={styles.imgWrapper}>
                            <img src={item.oss_uri} alt="item" onError={imgOnError}/>
                          </Box>
                          <Box className={styles.info}>
                            <Typography
                              variant="p"
                              component="p"
                              className={styles.tokenId}
                            >
                              #{item.token_id}
                            </Typography>
                            <Typography
                              variant="p"
                              component="p"
                              className={styles.name}
                            >
                              {item.name}
                            </Typography>
                          </Box>
                          <Box>
                            <Price
                              width={16} height={16}
                              price={item?.list_price}
                              showDollar={true}
                              sx={{ textAlign: 'right', fontSize: '16px', fontWeight: 600, lineHeight: '24px' }}
                              dollarSx={{ textAlign: 'right' }}
                            />
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                )}

                {cartState?.fail?.length > 0 && (
                  <Box className={styles.fail}>
                    <Box className={styles.title}>
                      <Typography
                        variant="h1"
                        component="h1"
                      >
                        Failure {cartState?.fail?.length ? `(${cartState?.fail.length})` : ''}
                      </Typography>
                    </Box>
                    <Box className={styles.itemContainer}>
                      {cartState?.fail?.map(item => (
                        <Box key={item.id} className={styles.item}>
                          <Box className={styles.imgWrapper}>
                            <img src={item.oss_uri} alt="item" onError={imgOnError}/>
                          </Box>
                          <Box className={styles.info}>
                            <Typography
                              variant="p"
                              component="p"
                              className={styles.tokenId}
                            >
                              #{item.token_id}
                            </Typography>
                            <Typography
                              variant="p"
                              component="p"
                              className={styles.name}
                            >
                              {item.name}
                            </Typography>
                            <Typography variant="p" component="p" className={styles.changed}>
                              <InfoOutlinedIcon />
                              Changed
                            </Typography>
                          </Box>
                          <Price
                            width={16} height={16}
                            price={item?.list_price}
                            showDollar={true}
                            sx={{
                              textAlign: 'right',
                              fontSize: '16px',
                              fontWeight: 600,
                              lineHeight: '24px',
                              opacity: 0.2,
                            }}
                            dollarSx={{ textAlign: 'right' }}
                          />
                        </Box>
                      ))}
                    </Box>
                  </Box>
                )}
              </Box>

              <Box className={styles.footer}>
                {currentCart?.length > 0 && (
                  <Box className={styles.total}>
                    <Typography variant="p" component="p">You Pay</Typography>
                    <Price
                      width={16} height={16}
                      price={totalPrice}
                      showDollar={true}
                      sx={{ textAlign: 'right', fontSize: '16px', fontWeight: 600, lineHeight: '24px' }}
                      dollarSx={{ textAlign: 'right' }}
                    />
                  </Box>
                )}

                <Button
                  fullWidth
                  variant="contained"
                  onClick={handleToMySpace}
                  sx={{
                    fontSize: '16px',
                    fontWeight: 600,
                    height: '60px',
                    marginTop: '16px',
                    borderRadius: '10px',
                    backgroundColor: '#5856d6',
                    '&.Mui-disabled': {
                      opacity: '0.2',
                      color: '#fff !important',
                      backgroundColor: '#5856d6 !important',
                    },
                  }}
                >View My Space
                </Button>
              </Box>

            </DialogContentText>
          </DialogContent>
        </Dialog>
      ) : (
        <Drawer
          anchor="bottom"
          open={cartState?.feedback}
          onClose={handleClose}
          sx={{
            '.MuiPaper-root': {
              borderTopLeftRadius: 16,
              borderTopRightRadius: 16,
            },
          }}
        >
          <Box className={styles.drawer}>
            <Typography
              variant="h1"
              component="h1"
              className={styles.title}
            >
              {'You did it!'}
              <CloseIcon onClick={handleClose} />
            </Typography>

            <Box className={styles.successContainer}>
              {cartState?.success?.length > 0 && (
                <Box className={styles.success}>
                  <Typography
                    variant="h1"
                    component="h1"
                  >
                    Success {cartState?.success?.length ? `(${cartState?.success?.length})` : ''}
                  </Typography>
                  <Box className={styles.itemContainer} sx={{ maxHeight: cartState?.fail?.length ? '' : 'calc(75vh - 250px) !important' }}>
                    {cartState?.success?.map(item => (
                      <Box key={item.id} className={styles.item}>
                        <Box className={styles.imgWrapper}>
                          <img src={item.oss_uri} alt="item" onError={imgOnError}/>
                        </Box>
                        <Box className={styles.info}>
                          <Typography
                            variant="p"
                            component="p"
                            className={styles.tokenId}
                          >
                            #{item.token_id}
                          </Typography>
                          <Typography
                            variant="p"
                            component="p"
                            className={styles.name}
                          >
                            {item.name}
                          </Typography>
                        </Box>
                        <Box>
                          <Price
                            width={16} height={16}
                            price={item?.list_price}
                            showDollar={true}
                            sx={{ textAlign: 'right', fontSize: '16px', fontWeight: 600, lineHeight: '24px' }}
                            dollarSx={{ textAlign: 'right' }}
                          />
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Box>
              )}

              {cartState?.fail?.length > 0 && (
                <Box className={styles.fail}>
                  <Typography
                    variant="h1"
                    component="h1"
                  >
                    Failure {cartState?.fail?.length ? `(${cartState?.fail?.length})` : ''}
                  </Typography>
                  <Box className={styles.itemContainer} sx={{ maxHeight: cartState?.success?.length ? '' : 'calc(75vh - 250px) !important' }}>
                    {cartState?.fail?.map(item => (
                      <Box key={item.id} className={styles.item}>
                        <Box className={styles.imgWrapper}>
                          <img src={item.oss_uri} alt="item" onError={imgOnError}/>
                        </Box>
                        <Box className={styles.info}>
                          <Typography
                            variant="p"
                            component="p"
                            className={styles.tokenId}
                          >
                            #{item.token_id}
                          </Typography>
                          <Typography
                            variant="p"
                            component="p"
                            className={styles.name}
                          >
                            {item.name}
                          </Typography>
                          <Typography variant="p" component="p" className={styles.changed}>
                            <InfoOutlinedIcon />
                            Changed
                          </Typography>
                        </Box>
                        <Price
                          width={16} height={16}
                          price={item?.list_price}
                          showDollar={true}
                          sx={{
                            textAlign: 'right',
                            fontSize: '16px',
                            fontWeight: 600,
                            lineHeight: '24px',
                            opacity: 0.2,
                          }}
                          dollarSx={{
                            textAlign: 'right',
                          }}
                        />
                      </Box>
                    ))}
                  </Box>
                </Box>
              )}
            </Box>

            <Box className={styles.footer}>
              {cartState?.success?.length > 0 && (
                <Box className={styles.total}>
                  <Typography variant="p" component="p">You Pay</Typography>
                  <Price
                    width={16} height={16}
                    price={totalPrice}
                    showDollar={true}
                    sx={{ fontSize: '16px', fontWeight: 600, lineHeight: '24px' }}
                    dollarSx={{ textAlign: 'right' }}
                  />
                </Box>
              )}
              <Button
                variant="contained"
                onClick={handleToMySpace}
                sx={{
                  fontSize: '16px',
                  fontWeight: 600,
                  width: 'calc(100% - 48px)',
                  height: '60px',
                  marginTop: '16px',
                  borderRadius: '10px',
                  backgroundColor: '#5856d6',
                }}
              >View My Space
              </Button>
            </Box>
          </Box>
        </Drawer>
      )}
    </>

  )
}

export default CartFeedback