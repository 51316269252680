
import { BigNumber, ethers } from 'ethers'
import { get_XY_cancel_input } from '@pages/api/trade'

export const INTENT_SELL = 1
export const INTENT_AUCTION = 2
export const INTENT_BUY = 3
export const DELEGATION_TYPE_INVALID = 0
export const DELEGATION_TYPE_ERC721 = 1
export const DELEGATION_TYPE_ERC1155 = 2

const orderItemParamType = 'tuple(uint256 price, bytes data)'
const orderParamType = `tuple(uint256 salt, address user, uint256 network, uint256 intent, uint256 delegateType, uint256 deadline, address currency, bytes dataMask, ${orderItemParamType}[] items, bytes32 r, bytes32 s, uint8 v, uint8 signVersion)`
const orderParamTypes = [
  'uint256',
  'address',
  'uint256',
  'uint256',
  'uint256',
  'uint256',
  'address',
  'bytes',
  'uint256',
  `${orderItemParamType}[]`,
]
const cancelInputParamType = 'tuple(bytes32[] itemHashes, uint256 deadline, uint8 v, bytes32 r, bytes32 s)'
const feeParamType = 'tuple(uint256 percentage, address to)'
const settleDetailParamType = `tuple(uint8 op, uint256 orderIdx, uint256 itemIdx, uint256 price, bytes32 itemHash, address executionDelegate, bytes dataReplacement, uint256 bidIncentivePct, uint256 aucMinIncrementPct, uint256 aucIncDurationSecs, ${feeParamType}[] fees)`
const settleSharedParamType = 'tuple(uint256 salt, uint256 deadline, uint256 amountToEth, uint256 amountToWeth, address user, bool canFail)'
const runInputParamType = `tuple(${orderParamType}[] orders, ${settleDetailParamType}[] details, ${settleSharedParamType} shared, bytes32 r, bytes32 s, uint8 v)`

const data1155ParamType = 'tuple(address token, uint256 tokenId, uint256 amount)[]'
const data721ParamType = 'tuple(address token, uint256 tokenId)[]'

export function randomSalt() {
  const randomHex = BigNumber.from(ethers.utils.randomBytes(16)).toHexString()
  return ethers.utils.hexZeroPad(randomHex, 64)
}

export function encodeOrder(order) {
  return ethers.utils.defaultAbiCoder.encode([orderParamType], [order])
}

export function encodeItemData(data) {
  if (data[0]?.tokenStandard === 'erc1155') {
    return ethers.utils.defaultAbiCoder.encode([data1155ParamType], [data])
  }
  return ethers.utils.defaultAbiCoder.encode([data721ParamType], [data])

}

function fixSignature(data) {
  // in geth its always 27/28, in ganache its 0/1. Change to 27/28 to prevent
  // signature malleability if version is 0/1
  // see https://github.com/ethereum/go-ethereum/blob/v1.8.23/internal/ethapi/api.go#L465
  if (data.v < 27) {
    data.v = data.v + 27
  }
}

//订单签名
async function signOrder(
  signer,
  order
) {
  const orderData = ethers.utils.defaultAbiCoder.encode(
    orderParamTypes,
    [
      order.salt,
      order.user,
      order.network,
      order.intent,
      order.delegateType,
      order.deadline,
      order.currency,
      order.dataMask,
      order.items.length,
      order.items,
    ]
  )
  const orderHash = ethers.utils.keccak256(orderData)
  // signMessage
  const orderSig = await signer.signMessage(ethers.utils.arrayify(orderHash))
  order.r = `0x${orderSig.slice(2, 66)}`
  order.s = `0x${orderSig.slice(66, 130)}`
  order.v = parseInt(orderSig.slice(130, 132), 16)
  fixSignature(order)
  return { orderSig, orderHash }
}

//卖单签名
export async function signSellOrder(
  signer,
  order
) {
  const data = await signOrder(signer, order)
  return data
}

//构造订单
function makeSellOrder(
  network,
  user,
  expirationTime,
  items,
  tokenStandard
) {

  if (expirationTime < Math.round(Date.now() / 1000) + 900) {
    throw new Error('The expiration time has to be 15 minutes later.')
  }
  const salt = randomSalt()
  return {
    salt,
    user,
    network,
    intent: INTENT_SELL,
    delegateType:
    tokenStandard === 'erc1155'
      ? DELEGATION_TYPE_ERC1155
      : DELEGATION_TYPE_ERC721,
    deadline: expirationTime,
    currency: ethers.constants.AddressZero,
    dataMask: '0x',
    items,
    r: '',
    s: '',
    v: 0,
    signVersion: 1,
  }
}

//获取X2Y2 list 订单结构体积，后端聚合需要用
export async function getSellOrder({
  signer,
  tokenAddress,
  tokenId,
  tokenStandard,
  price,
  expirationTime,
  network = 1,
  isChangePrice,
}) {
  const accountAddress = await signer.getAddress()

  const data = encodeItemData([
    {
      token: tokenAddress,
      tokenId,
      amount: 1,
      tokenStandard: tokenStandard ?? 'erc721',
    },
  ])
  const order = makeSellOrder(
    network,
    accountAddress,
    expirationTime,
    [{ price, data }],
    tokenStandard
  )
  const sigData = await signSellOrder(signer, order)
  //接口需要的结构
  //https://yfxzoa4pw9.feishu.cn/docx/VyTJdsnIvo4wnRxCKHscpE8BnEh
  return {
    sigData,
    data: {
      order: encodeOrder(order),
      isBundle: false,
      bundleName: '',
      bundleDesc: '',
      orderIds: [],
      royalties: [],
      changePrice: isChangePrice,
      isCollection: false,
      isPrivate: false,
      taker: null,
    },
  }
}

export function decodeCancelInput(input) {
  return ethers.utils.defaultAbiCoder.decode(
    [cancelInputParamType],
    input
  )[0]
}

//获取取消订单输入信息
export async function getCancelInput({
  caller, //用户地址
  order_id,
  signer,
}) {

  //https://github.com/X2Y2-io/x2y2-sdk/blob/a3a425072ef53a048988f3eeea44e192419e8247/src/index.ts#LL319C51-L319
  const signMessage = ethers.utils.keccak256('0x')
  const sign = await signer.signMessage(ethers.utils.arrayify(signMessage))

  const res = await get_XY_cancel_input({
    caller,
    order_id,
    sign,
    market: 3,
  })

  if ( !res?.data?.input) {
    throw new Error('Invalid order')
  }

  return decodeCancelInput(res?.data?.input)
}