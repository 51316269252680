// 
import { getProvider } from '@wagmi/core'

const getDefaultProvider = async () => {
  // // 不登录获取provider是需要区分eth还是ethw
  // const urlObj = {
  //   10001: 'https://mainnet.ethereumpow.org/',
  //   56: 'https://bsc-dataseed.binance.org/'
  // }
  
  // const testChainUrlObj = {
  //   4:  'rinkeby',
  //   5: 'goerli',
  // }

  // const chain = localStorage.getItem('chain')
  // if (chain === '10001' || chain === '56') {
  //   let url = urlObj[chain];
  //   let customHttpProvider = new ethers.providers.JsonRpcProvider(url);
  //   return customHttpProvider
  // }
  //   const  testRPC =  testChainUrlObj[chain] || 'rinkeby'
  //   const currentEnv = isProduction() ? "homestead" : testRPC
  //   const provider = ethers.getDefaultProvider(currentEnv,{
  //     alchemy:  isProduction() ? '8CjGbGbtrAI1zB7YuLZujI6SJVGKOerP':'HW1kQ7rfo83a9neLIJOaaW_FczMJFqv8',
  //   })
  //   return provider
  return  getProvider()
}

export default getDefaultProvider
