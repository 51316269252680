/**
 * gitlab.com/richard247/contract
 * 聚合swap
 * 1、先从接口获取订单数组
 * 2、根据不同市场执行组装逻辑
 * 3、把组装数据交给合约
 */
// import _ from 'lodash'
import {
  BigNumber,
} from 'ethers'
import { RoutePlanner, CommandType } from './planner'
import { getAdvancedOrderParams } from './utils'
import { OPENSEA_CONDUIT_KEY, ETH_ADDRESS, DEADLINE } from './constants'

import { TRADE_AMOUNT_MAIN_ADDRESS } from '@/contract/Address'
import { get_XY_order_sign } from '@pages/api/trade'

import { createNswapOrders, nswapInterface } from './nswap'
import { X2Y2_INTERFACE } from './x2y2'
import { getSeaportInterface } from './seaport'

const Nswap = 0
const Opensea = 1
const X2Y2 = 3

//对聚合订单执行swap
export const executeSwap = async ({
  buyAddress, //用户地址
  ordersList = [], //订单列表
}) => {
  // 对订单遍历，执行不同的数据组装
  console.log('当前有效订单', ordersList)

  const planner = new RoutePlanner()
  let totalValue = BigNumber.from(0) //总金额
  const promises = [] //保证异步操作能正常拿到数据

  for (const item of ordersList) {
    const { market_id, data, signature } = item
    const order = JSON.parse(data)
    console.log(order)

    //prepare nswap order
    if (market_id === Nswap) {
      const { makerOrder, takerOrder, value } = createNswapOrders(
        order,
        TRADE_AMOUNT_MAIN_ADDRESS, //聚合交易合约地址
      )

      const nswapTakerOrder = takerOrder
      const nswapMakerOrder = makerOrder
      const nswapSignature = signature
      const nswapTokenId = order.nft.assetType.tokenID
      const nswapNftAddress = order.nft.assetType.tokenAddress

      const nswapCalldata = nswapInterface.encodeFunctionData('matchOrders', [
        nswapMakerOrder,
        nswapTakerOrder,
        nswapSignature,
      ])
      planner.addCommand(CommandType.NSWAP_721, [value, nswapCalldata, buyAddress, nswapNftAddress, nswapTokenId], true)

      totalValue = totalValue.add(value)
    }

    //prepare opensea order
    if (market_id === Opensea) {
      //有了新版本
      const isV14 = order.protocol_address?.toLocaleLowerCase() === '0x00000000000001ad428e4906ae43d8f9852d0dd6'?.toLocaleLowerCase()
      const { advancedOrder, value } = getAdvancedOrderParams({
        ...order,
        signature,
      })

      if (!advancedOrder || !value) return
      const seaportInterface = getSeaportInterface(isV14)

      const calldata = seaportInterface.encodeFunctionData('fulfillAdvancedOrder', [
        advancedOrder,
        [],
        OPENSEA_CONDUIT_KEY,
        buyAddress,
      ])
      if (isV14) {
        planner.addCommand(CommandType.SEAPORTV14, [value.toString(), calldata], true)
      } else {
        planner.addCommand(CommandType.SEAPORT, [value.toString(), calldata], true)
      }
      totalValue = totalValue.add(value)
    }

    //prepare X2Y2 order
    if (market_id === X2Y2) {
      const promise = get_XY_order_sign({
        caller: TRADE_AMOUNT_MAIN_ADDRESS,
        items: [{
          orderId: order.id,
          tokenId: order.token.token_id,
          currency: order.currency,
          price: order.price,
        }],
        market: 3,
        check: true,
      }).then((signData) => {
        const x2y2Orders = signData?.data?.result || []
        const x2y2Order = x2y2Orders[0]

        if (!x2y2Order) return

        const functionSelector = X2Y2_INTERFACE.getSighash(X2Y2_INTERFACE.getFunction('run'))
        const x2y2Calldata = functionSelector + x2y2Order.input.slice(2)
        const x2y2NftAddress = order.token.contract
        const x2y2Tokenid = order.token.token_id

        planner.addCommand(CommandType.X2Y2_721, [
          order.price,
          x2y2Calldata,
          buyAddress,
          x2y2NftAddress,
          x2y2Tokenid,
        ], true)
        totalValue = totalValue.add(order.price)
      })

      promises.push(promise)
    }
  }

  await Promise.all(promises)

  // sweep when some orders failed
  planner.addCommand(CommandType.SWEEP, [ETH_ADDRESS, buyAddress, 0])

  const { commands, inputs } = planner.getData()

  return {
    totalValue,
    commands,
    inputs,
    DEADLINE,
  }
}