import React, { useState } from 'react'
import { useRouter } from 'next/router'
import { Drawer, Box, Badge } from '@mui/material'
import styles from '@/assets/Header.module.scss'
import { SHOP_CART } from '@utils/img'
import { openMetamaskApp } from '@/utils/utils'
import { showSnackBar } from '@components/CustomizedSnackbars'
import { getChainName } from '@utils/utils'
import { useAccount } from 'wagmi'
import useConnect from '@/hooks/useConnect'

import SearchBar from './SearchBar'
import ChainSelect from './ChainSelect'

import { logoUrl_0_3_3, TOP_LOGO, headerNavs } from './constants'

// 移动端菜单
export default function MMemu({
  tabValue,
  currentCartNum,
  cartDispatch,
}) {
  const router = useRouter()
  const [isMobileFilterShow, setIsMobileFilterShow] = useState(false)
  const [isSearchAreaShow, setIsSearchAreaShow] = useState()
  const { isConnected } = useAccount()
  const { connect, disconnect } = useConnect()

  const handleLogin = () => {
    if (isConnected) {
      setIsMobileFilterShow(false)
      disconnect()
    } else {
      setIsMobileFilterShow(false)
      connect()
    }
  }

  const handleClickRedirectHome = () => {
    handleChangeMobileMenu(false)
    router.push(`/${getChainName()}`)
  }

  const handleChangeMobileMenu = (status) => {
    setIsMobileFilterShow(status)
  }

  const handleShowSearchArea = () => {
    setIsSearchAreaShow(true)
  }

  return !isSearchAreaShow ? (
    <>
      <nav className={styles.header_box}>
        <div className={styles.header_logo} onClick={handleClickRedirectHome}>
          <img src={TOP_LOGO} height={72} width={136} alt="" />
        </div>
        <div className={styles.header_right}>
          <div className={styles.menu_icon} >
            <ChainSelect isPhone={true}/>
            <img
              src="https://s3-nswap-base.nswap.com/0.3.3/search_icon_3x.png"
              width={30}
              height={30}
              alt=""
              onClick={handleShowSearchArea}
            />
            <Badge
              badgeContent={currentCartNum}
              color="primary"
              sx={{
                '@media screen and (max-width:750px)': {
                  '& .MuiBadge-badge': {
                    fontSize: '12px',
                  },
                },

              }}
            >
              <div className={styles.header_connect}
                onClick={() => {
                  cartDispatch({ type: 'visible', payload: true })
                }}
              >
                <img src={SHOP_CART} alt="" />
              </div>
            </Badge>
            <img
              src={`${logoUrl_0_3_3}header_menu_icon.png`}
              width={30}
              height={30}
              style={{ marginLeft: '10px' }}
              alt=""
              onClick={() => handleChangeMobileMenu(!isMobileFilterShow)}
            />
          </div>
        </div>
      </nav>
      <Drawer anchor={'bottom'} sx={{
        '& .MuiPaper-root': {
          boxShadow: 'none',
        },
        '& .MuiBackdrop-root': {
          background: 'rgba(0,0,0,.01)',
        },
      }} open={isMobileFilterShow} onClose={() => setIsMobileFilterShow(false)}
      >
        <Box sx={{ width: '100vw', height: 'calc(100vh - 72px)' }}>
          <div className={styles.mobile_header_menu}>
            <div className={styles.mobile_tabs}>
              {headerNavs.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={styles.mobile_tab_item}
                    onClick={() => {
                      if (['/user', '/community'].includes(item.url)) {
                        openMetamaskApp()
                      }
                      if (item.url === '/community') {
                        const address = localStorage.getItem('address')
                        if (address === 'null') {
                          showSnackBar({ type: 'error', message: 'Please sign in.' })
                        } else {
                          window.open(`https://bbs.nswap.com/User/Login?address=${address}`)
                        }
                        setIsMobileFilterShow(false)
                        return
                      }

                      router.push(item.url)
                      setIsMobileFilterShow(false)
                    }}
                  >
                    <div className={styles.mobile_tab_text}>
                      <img src={`${logoUrl_0_3_3}${item.icon}`} />
                      <span>{item.label}</span>
                    </div>

                    <div className={styles.right_arrow}>
                      <img src={`${logoUrl_0_3_3}right_arrow_icon.png`} />
                    </div>
                  </div>
                )
              })}
            </div>
            <div className={styles.mobile_headerButCon}>
              <div className={styles.mobile_headerBut} onClick={handleLogin}>
                {isConnected ? 'Log out' : 'Connect wallet'}
              </div>
            </div>
          </div>

        </Box>
      </Drawer>
    </>
  ) : (
    <SearchBar
      tabValue={tabValue}
      isPhone
      onClose={setIsSearchAreaShow}
    />
  )
}
