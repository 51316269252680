import { useState } from 'react'
import Image from 'next/image'
import { ITEM_DEFAULT_URI } from '@utils/img'

function ComImage(props) {
  const { src } = props
  const [hasError, setHasError] = useState(false)
  if (!src) return null
  return (
    <>
      {hasError ? (
        <img src={ITEM_DEFAULT_URI} alt="Error" style={{ borderRadius: '2px' }}/>
      ) : (
        <Image
          {...props}
          // objectFit="contain"
          onError={() => setHasError(true)}
        />
      )}

    </>
  )
}

export default ComImage