import { ethers } from 'ethers';
import { EXCHANGE_ADDRESS, EXCHANGE_MAIN_ADDRESS, BSC_EXCHANGE_MAIN_ADDRESS, BSC_EXCHANGE_TEST_ADDRESS } from '../Address.js'
import ExchangeABI from '../ABI/EXCHANGE.json'
import getSigner from './getSigner.js';
// import { getEnv } from '@utils/utils.js';
// import { EnvType } from '@utils/constant.js';
import { isProduction } from '@utils/utils'
import getDefaultProvider from './getDefaultProvider';


const getExchangeInstance = async (readOnly = false) => {
    // const env = getEnv()
    const provider = await getDefaultProvider()
    const chain = localStorage.getItem('chain') || '1'
    let address
    if (chain === '56') {
        address = isProduction() ? BSC_EXCHANGE_MAIN_ADDRESS : BSC_EXCHANGE_TEST_ADDRESS
    } else {
        address = isProduction() ? EXCHANGE_MAIN_ADDRESS : EXCHANGE_ADDRESS
    }
    if (!readOnly) {
        const signer = await getSigner(true)
        if (!signer) return
        return new ethers.Contract(address, ExchangeABI, signer);
    }
    return new ethers.Contract(address, ExchangeABI, provider)
}
export default getExchangeInstance;
