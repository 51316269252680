import { providers as multicallProviders } from '@0xsequence/multicall'
import {
  // BigNumber,
  // BigNumberish,
  Contract,
  ethers,
  // PayableOverrides,
  providers,
} from 'ethers'
// import { _TypedDataEncoder } from 'ethers/lib/utils'
import { DomainRegistryABI } from './abi/DomainRegistry'
import { SeaportABI } from './abi/Seaport'
import { SeaportABIv12 } from './abi/Seaport_v1_2'
import {
  // SEAPORT_CONTRACT_NAME,
  // SEAPORT_CONTRACT_VERSION,
  // SEAPORT_CONTRACT_VERSION_V1_2,
  // EIP_712_ORDER_TYPE,
  KNOWN_CONDUIT_KEYS_TO_CONDUIT,
  MAX_INT,
  NO_CONDUIT,
  OPENSEA_CONDUIT_KEY,
  OrderType,
  CROSS_CHAIN_SEAPORT_ADDRESS,
  DOMAIN_REGISTRY_ADDRESS,
  CROSS_CHAIN_SEAPORT_V1_2_ADDRESS,
} from './constants'
import { getApprovalActions } from './approval'
import {
  getBalancesAndApprovals,
  validateOfferBalancesAndApprovals,
} from './balanceAndApprovalCheck'
// import { getBulkOrderTree } from './utils/eip712/bulk-orders'
// import {
//   fulfillAvailableOrders,
//   fulfillBasicOrder,
//   FulfillOrdersMetadata,
//   fulfillStandardOrder,
//   shouldUseBasicFulfill,
//   validateAndSanitizeFromOrderStatus,
// } from './utils/fulfill'
import { getMaximumSizeForOrder, isCurrencyItem } from './item'
import {
  areAllCurrenciesSame,
  deductFees,
  feeToConsiderationItem,
  generateRandomSalt,
  generateRandomSaltWithDomain,
  mapInputItemToOfferItem,
  totalItemsAmount,
} from './order'
// import { executeAllActions, getTransactionMethods } from './utils/usecase'

export class Seaport {
  // Provides the raw interface to the contract for flexibility
   contract;

   domainRegistry;

   provider;

   signer;

   // Use the multicall provider for reads for batching and performance optimisations
   // NOTE: Do NOT await between sequential requests if you're intending to batch
   // instead, use Promise.all() and map to fetch data in parallel
   // https://www.npmjs.com/package/@0xsequence/multicall
   multicallProvider;

   config;

   defaultConduitKey;

  OPENSEA_CONDUIT_KEY= OPENSEA_CONDUIT_KEY;

  /**
   * @param providerOrSigner - The provider or signer to use for web3-related calls
   * @param considerationConfig - A config to provide flexibility in the usage of Seaport
   */
  constructor(
    providerOrSigner,
    {
      overrides,
      // Five minute buffer
      ascendingAmountFulfillmentBuffer = 300,
      balanceAndApprovalChecksOnOrderCreation = true,
      conduitKeyToConduit,
      seaportVersion = '1.4',
    } = {}
  ) {
    const provider =
      providerOrSigner instanceof providers.Provider
        ? providerOrSigner
        : providerOrSigner.provider
    this.signer = (providerOrSigner)._isSigner
      ? (providerOrSigner)
      : undefined

    if (!provider) {
      throw new Error(
        'Either a provider or custom signer with provider must be provided'
      )
    }

    this.provider = provider

    this.multicallProvider = new multicallProviders.MulticallProvider(
      this.provider
    )

    this.contract = new Contract(
      overrides?.contractAddress ??
        (seaportVersion === '1.2'
          ? CROSS_CHAIN_SEAPORT_V1_2_ADDRESS
          : CROSS_CHAIN_SEAPORT_ADDRESS),
      seaportVersion === '1.2' ? SeaportABIv12 : SeaportABI,
      this.multicallProvider
    )

    this.domainRegistry = new Contract(
      overrides?.domainRegistryAddress ?? DOMAIN_REGISTRY_ADDRESS,
      DomainRegistryABI,
      this.multicallProvider
    )

    this.config = {
      ascendingAmountFulfillmentBuffer,
      balanceAndApprovalChecksOnOrderCreation,
      conduitKeyToConduit: {
        ...KNOWN_CONDUIT_KEYS_TO_CONDUIT,
        [NO_CONDUIT]: this.contract.address,
        ...conduitKeyToConduit,
      },
      seaportVersion,
    }

    this.defaultConduitKey = overrides?.defaultConduitKey ?? NO_CONDUIT
  }

  /**
   * Returns a use case that will create an order.
   * The use case will contain the list of actions necessary to finish creating an order.
   * The list of actions will either be an approval if approvals are necessary
   * or a signature request that will then be supplied into the final Order struct, ready to be fulfilled.
   *
   * @param input
   * @param input.conduitKey The conduitKey key to derive where to source your approvals from. Defaults to 0 which refers to the Seaport contract.
   *                         Another special value is address(1) will refer to the legacy proxy. All other must derive to the specified address.
   * @param input.zone The zone of the order. Defaults to the zero address.
   * @param input.startTime The start time of the order. Defaults to the current unix time.
   * @param input.endTime The end time of the order. Defaults to "never end".
   *                      It is HIGHLY recommended to pass in an explicit end time
   * @param input.offer The items you are willing to offer. This is a condensed version of the Seaport struct OfferItem for convenience
   * @param input.consideration The items that will go to their respective recipients upon receiving your offer.
   * @param input.counter The counter from which to create the order with. Automatically fetched from the contract if not provided
   * @param input.allowPartialFills Whether to allow the order to be partially filled
   * @param input.restrictedByZone Whether the order should be restricted by zone
   * @param input.fees Convenience array to apply fees onto the order. The fees will be deducted from the
   *                   existing consideration items and then tacked on as new consideration items
   * @param input.domain An optional domain to be hashed and included in the first four bytes of the random salt.
   * @param input.salt Arbitrary salt. If not passed in, a random salt will be generated with the first four bytes being the domain hash or empty.
   * @param input.offerer The order's creator address. Defaults to the first address on the provider.
   * @param accountAddress Optional address for which to create the order with
   * @param exactApproval optional boolean to indicate whether the approval should be exact or not
   * @returns a use case containing the list of actions needed to be performed in order to create the order
   */
  async createOrder(
    input,
    accountAddress,
    exactApproval
  ) {
    const signer = this._getSigner(accountAddress)
    const offerer = await signer.getAddress()

    const { orderComponents, approvalActions } = await this._formatOrder(
      signer,
      offerer,
      Boolean(exactApproval),
      input
    )
    console.log(orderComponents, 'orderComponents---')
    return orderComponents
    // return 拿到这个orderComponents
  }

  /**
   * Formats an order for creation.
   */
  async _formatOrder(
    signer,
    offerer,
    exactApproval,
    {
      conduitKey = this.defaultConduitKey,
      zone = ethers.constants.AddressZero,
      startTime = Math.floor(Date.now() / 1000).toString(),
      endTime = MAX_INT.toString(),
      offer,
      consideration,
      counter,
      allowPartialFills,
      restrictedByZone,
      fees,
      domain,
      salt,
    }
  ) {
    const offerItems = offer.map(mapInputItemToOfferItem)
    const considerationItems = [
      ...consideration.map(consideration => ({
        ...mapInputItemToOfferItem(consideration),
        recipient: consideration.recipient ?? offerer,
      })),
    ]
    console.log('first')

    if (
      !areAllCurrenciesSame({
        offer: offerItems,
        consideration: considerationItems,
      })
    ) {
      throw new Error(
        'All currency tokens in the order must be the same token'
      )
    }

    const currencies = [...offerItems, ...considerationItems].filter(
      isCurrencyItem
    )

    console.log(currencies, 'currencies--')

    const totalCurrencyAmount = totalItemsAmount(currencies)

    const operator = this.config.conduitKeyToConduit[conduitKey]

    console.log('second--')

    const orderType = this._getOrderTypeFromOrderOptions({
      allowPartialFills,
      restrictedByZone,
    })
    console.log('hhh')

    const considerationItemsWithFees = [
      ...deductFees(considerationItems, fees),
      ...(currencies.length
        ? fees?.map(fee =>
          feeToConsiderationItem({
            fee,
            token: currencies[0].token,
            baseAmount: totalCurrencyAmount.startAmount,
            baseEndAmount: totalCurrencyAmount.endAmount,
          })) ?? []
        : []),
    ]
    console.log('third')

    const saltFollowingConditional =
      salt ||
      (domain ? generateRandomSaltWithDomain(domain) : generateRandomSalt())
    console.log('four')
    const orderComponents = {
      offerer,
      zone,
      zoneHash: ethers.constants.HashZero,
      startTime,
      endTime,
      orderType,
      offer: offerItems,
      consideration: considerationItemsWithFees,
      // totalOriginalConsiderationItems: considerationItemsWithFees.length,
      salt: saltFollowingConditional,
      conduitKey,
      // counter: counter ?? (await this.getCounter(offerer)),
      counter: 0,
    }

    const approvalActions = []

    // if (this.config.balanceAndApprovalChecksOnOrderCreation) {
    //   const balancesAndApprovals = await getBalancesAndApprovals({
    //     owner: offerer,
    //     items: offerItems,
    //     criterias: [],
    //     multicallProvider: this.multicallProvider,
    //     operator,
    //   })

    //   const insufficientApprovals = validateOfferBalancesAndApprovals({
    //     offer: offerItems,
    //     criterias: [],
    //     balancesAndApprovals,
    //     throwOnInsufficientBalances: true,
    //     operator,
    //   })

    //   const approvals = await getApprovalActions(
    //     insufficientApprovals,
    //     exactApproval,
    //     signer
    //   )
    //   approvalActions.push(...approvals)
    // }

    return { orderComponents, approvalActions }
  }

  _getSigner(accountAddress) {
    if (this.signer) {
      return this.signer
    }

    if (!(this.provider instanceof providers.JsonRpcProvider)) {
      throw new Error('Either signer or a JsonRpcProvider must be provided')
    }

    return this.provider.getSigner(accountAddress)
  }

  _getOrderTypeFromOrderOptions({
    allowPartialFills,
    restrictedByZone,
  }) {
    if (allowPartialFills) {
      return restrictedByZone
        ? OrderType.PARTIAL_RESTRICTED
        : OrderType.PARTIAL_OPEN
    }

    return restrictedByZone ? OrderType.FULL_RESTRICTED : OrderType.FULL_OPEN
  }

  getCounter(offerer) {
    return this.contract.getCounter(offerer)
  }
}

/**
   * Calculates the order hash of order components so we can forgo executing a request to the contract
   * This saves us RPC calls and latency.
  */
export const getOrderHash = (orderComponents) => {
  const offerItemTypeString =
      'OfferItem(uint8 itemType,address token,uint256 identifierOrCriteria,uint256 startAmount,uint256 endAmount)'
  const considerationItemTypeString =
      'ConsiderationItem(uint8 itemType,address token,uint256 identifierOrCriteria,uint256 startAmount,uint256 endAmount,address recipient)'
  const orderComponentsPartialTypeString =
      'OrderComponents(address offerer,address zone,OfferItem[] offer,ConsiderationItem[] consideration,uint8 orderType,uint256 startTime,uint256 endTime,bytes32 zoneHash,uint256 salt,bytes32 conduitKey,uint256 counter)'
  const orderTypeString = `${orderComponentsPartialTypeString}${considerationItemTypeString}${offerItemTypeString}`

  const offerItemTypeHash = ethers.utils.keccak256(
    ethers.utils.toUtf8Bytes(offerItemTypeString)
  )
  const considerationItemTypeHash = ethers.utils.keccak256(
    ethers.utils.toUtf8Bytes(considerationItemTypeString)
  )
  const orderTypeHash = ethers.utils.keccak256(
    ethers.utils.toUtf8Bytes(orderTypeString)
  )

  const offerHash = ethers.utils.keccak256(
    `0x${
      orderComponents.offer
        .map((offerItem) => {
          return ethers.utils
            .keccak256(
              `0x${
                [
                  offerItemTypeHash.slice(2),
                  offerItem.itemType.toString().padStart(64, '0'),
                  offerItem.token.slice(2).padStart(64, '0'),
                  ethers.BigNumber.from(offerItem.identifierOrCriteria)
                    .toHexString()
                    .slice(2)
                    .padStart(64, '0'),
                  ethers.BigNumber.from(offerItem.startAmount)
                    .toHexString()
                    .slice(2)
                    .padStart(64, '0'),
                  ethers.BigNumber.from(offerItem.endAmount)
                    .toHexString()
                    .slice(2)
                    .padStart(64, '0'),
                ].join('')}`
            )
            .slice(2)
        })
        .join('')}`
  )

  const considerationHash = ethers.utils.keccak256(
    `0x${
      orderComponents.consideration
        .map((considerationItem) => {
          return ethers.utils
            .keccak256(
              `0x${
                [
                  considerationItemTypeHash.slice(2),
                  considerationItem.itemType.toString().padStart(64, '0'),
                  considerationItem.token.slice(2).padStart(64, '0'),
                  ethers.BigNumber.from(
                    considerationItem.identifierOrCriteria
                  )
                    .toHexString()
                    .slice(2)
                    .padStart(64, '0'),
                  ethers.BigNumber.from(considerationItem.startAmount)
                    .toHexString()
                    .slice(2)
                    .padStart(64, '0'),
                  ethers.BigNumber.from(considerationItem.endAmount)
                    .toHexString()
                    .slice(2)
                    .padStart(64, '0'),
                  considerationItem.recipient.slice(2).padStart(64, '0'),
                ].join('')}`
            )
            .slice(2)
        })
        .join('')}`
  )

  const derivedOrderHash = ethers.utils.keccak256(
    `0x${
      [
        orderTypeHash.slice(2),
        orderComponents.offerer.slice(2).padStart(64, '0'),
        orderComponents.zone.slice(2).padStart(64, '0'),
        offerHash.slice(2),
        considerationHash.slice(2),
        orderComponents.orderType.toString().padStart(64, '0'),
        ethers.BigNumber.from(orderComponents.startTime)
          .toHexString()
          .slice(2)
          .padStart(64, '0'),
        ethers.BigNumber.from(orderComponents.endTime)
          .toHexString()
          .slice(2)
          .padStart(64, '0'),
        orderComponents.zoneHash.slice(2),
        orderComponents.salt.slice(2).padStart(64, '0'),
        orderComponents.conduitKey.slice(2).padStart(64, '0'),
        ethers.BigNumber.from(orderComponents.counter)
          .toHexString()
          .slice(2)
          .padStart(64, '0'),
      ].join('')}`
  )

  return derivedOrderHash
}
