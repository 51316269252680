import getDaiInstance from './getDaiInstance.js'
import getExchangeInstance from './getExchangeInstance'
import getExchangeInstanceBatch from './getExchangeInstanceBatch'
import getNftInstance from './getNftInstance'
import getWethInstance from './getWethInstance.js'
import getMintInstance from './getMintInstance.js'
import getMobileExchange from './getMobileExchange.js'
import getTestInstance from './getTestInstance.js'
import getMobileNft from './getMobileNft'
import getBadgeInstance from './getBadgeInstance'
import getRobotInstance from './getRobotInstance'
import get_OS_Instance from './get_OS_Instance.js'
import get_XY_Instance from './get_XY_Instance.js'
import get_Trade_Instance from './get_TRADE_Instance.js'
export default {
    getDaiInstance,
    getExchangeInstance,
    getExchangeInstanceBatch,
    getNftInstance,
    getWethInstance,
    getMintInstance,
    getMobileExchange,
    getMobileNft,
    getTestInstance,
    getBadgeInstance,
    getRobotInstance,
    get_OS_Instance,
    get_XY_Instance,
    get_Trade_Instance
}