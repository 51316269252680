import { useState, useEffect, useMemo, useRef } from 'react'
import useWidth from '@/hooks/useWidth'
import classnames from 'classnames'
import config from '@/config/config'
import styles from '@/assets/Select.module.scss'

const logoUrl = `${config.assetsUrl}0.2.3/`

const Select = (props) => {
  const { normal, options, header, defaultValue, defaultLabel, onChange, chain, isHeader } = props

  const [label, setLabel] = useState(defaultLabel)
  const [value, setValue] = useState('')
  const [show, setShow] = useState(false)
  const [exceedWindow, setExceedWindow] = useState(false)
  const windowWidth = useWidth()

  const optionWrapRef = useRef()
  const mounted = useRef(false)

  const handleSelectValue = (item) => {
    setLabel(item.label)
    setValue(item.value)
    setShow(false)
    onChange && onChange(item)
  }

  const handleShowOperation = (e, status) => {
    e && e.stopPropagation()
    setShow(status)
  }

  const handleWindowClick = () => {
    handleShowOperation(null, false)
  }

  useEffect(() => {
    document.body.addEventListener('click', handleWindowClick, false)
    return () => {
      document.body.removeEventListener('click', handleWindowClick)
    }
  }, [])

  const wrapHeader = useMemo(() => {
    return (
      <div className={styles.option_label} onClick={e => handleShowOperation(e, true)}>
        {header || (
          <div
            className={classnames(
              styles.select_header,
              show ? styles.select_header_show : ''
            )}

          >
            <span>{label}</span>
            <img src={`${logoUrl}${show ? 'chevron_down_checked.png' : 'chevron_down.png'}`} alt="" />
          </div>
        )}
      </div>
    )
  }, [header, label, show])

  useEffect(() => {
    if (defaultValue !== undefined && defaultValue !== null) {
      setValue(defaultValue)
    }
  }, [defaultValue])

  useEffect(() => {
    mounted.current = true
  }, [])

  useEffect(() => {
    if (windowWidth > 0 && windowWidth < 1500 && header) {
      const eleWidth = optionWrapRef.current.getBoundingClientRect().left + optionWrapRef.current.clientWidth
      if (eleWidth + 200 < windowWidth) {
        setExceedWindow(false)
      } else {
        setExceedWindow(eleWidth - 50 < windowWidth)
      }
      return
    }
    setExceedWindow(false)
  }, [header, windowWidth])

  return (
    <div className={styles.select_ele}>
      {wrapHeader}
      <div
        ref={optionWrapRef}
        className={classnames(
          styles.options_wrap,
          !show ? styles.hide : '',
          !header ? styles.common_header : '',
          exceedWindow ? styles.exceed_window : ''
        )}
        style={{ top: normal ? 52 : 60, left: (header?.type === 'img' || isHeader) ? -190 : 0 }}
      >
        {show ? (
          <div className={styles.options_detail}>
            {options.map((item) => {
              return (
                <div
                  className={classnames(styles.select_item, item.value === value ? styles.checked : '')}
                  style={{ width: chain ? 160 : 240 }}
                  key={item.key || item.label}
                  onClick={(e) => {
                    handleSelectValue(item)
                    e.stopPropagation()
                    e.preventDefault()
                  }}
                >
                  {chain ? <img src={item.url} width={24} height={24} style={{ marginLeft: 16 }}/> : null}
                  <span>{item.label}</span>
                </div>
              )
            })}
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default Select