import { Order } from '../contract/TEST/order.js'
import getSigner from '@contract/libs/getSigner.js'
import { utils, ethers, BigNumber } from 'ethers'
import { Asset } from '@contract/TEST/order'
import { Nswap, Opensea, Looksrare, X2Y2 } from './constant.js'
import { isProduction } from '@utils/utils'
import { Seaport } from '@utils/seaportjs/seaport'
import { TRADE_MARKET_MAIN_ADDRESS, TRADE_MARKET_TEST_ADDRESS, TRADE_MARKET_APPROVE_ADDRESS, TRADE_MARKET_APPROVE_TEST_ADDRESS, BEST_MARKET } from '@contract/Address'
import { ACCEPT_OFFER, CANCEL_OFFER } from '@utils/constant'
import { ZERO, EXCHANGE_ADDRESS, ETH_ADDRESS, WETH_ADDRESS, WETH_MAIN_ADDRESS, EXCHANGE_MAIN_ADDRESS, BSC_EXCHANGE_MAIN_ADDRESS, BSC_EXCHANGE_TEST_ADDRESS } from '@contract/Address'
import { ETH, ERC20, ERC721 } from '@contract/TEST/assets'
import { signMakerOrder, addressesByNetwork, SupportedChainId } from '@looksrare/sdk'
import { get_LK_Nonce } from '@/api/trade'
import { getSellOrder as getXYSellOrder } from './x2y2'
import { string } from 'prop-types'
import CONTRACT from '@contract/libs/index'
import { getAdvancedOrderParams, DEADLINE, OPENSEA_CONDUIT_KEY } from './seaportjs/getBuyParam/utils.js'
import SEAPORT_ABI from '@contract/ABI/Seaport.json'
import { RoutePlanner, SEAPORT, SWEEP } from './seaportjs/getBuyParam/planner.js'

export const getTradeAddress = ( market ) => {
  return isProduction()
    ? TRADE_MARKET_MAIN_ADDRESS[market]
    : TRADE_MARKET_TEST_ADDRESS[market]
}

export const assOrder = (side, maker, taker, nft, price, start, end, salt) => {
  // return Order(side, 0, maker, taker, nft, price, Math.floor(Math.random() * 10000000), start, end, "0xffffffff", "0x")
  return Order(side, 0, maker, taker, nft, price, salt, start, end, '0xffffffff', '0x')
}

export const get_NS_SellOrder = async ({
  collectionAddress,
  nft_id,
  type, price, date, isChain, tokeAddress, salt,
}) => {
  // 链上单位是秒
  let startTime
  if (type === ACCEPT_OFFER) {
    startTime = 0
  } else {
    startTime = Math.floor(new Date().getTime() / 1000)
  }
  const validity = 60 * 60 * 24 * date
  const endTime = startTime + validity
  const signer = await getSigner(true)
  if (!signer) return {}
  const address = await signer.getAddress()
  if (!address) return {}
  const assetType = tokeAddress === ETH_ADDRESS ? ETH : ERC20
  // 传给链上的价格需要格式化，传给服务端的是普通的数字即可
  // 传给链上的nft_id是数字类型，传给服务端的是字符串
  let sellOrder
  if (isChain) {
    // 这里需要将给的nft_id转成数字类型,如果数字太大的话，会导致签名报错
    // const tokeID = Number(nft_id)
    const formatPrice = utils.parseEther(String(price))
    sellOrder = assOrder(0, address, ZERO,
      Asset(ERC721, collectionAddress, nft_id, 1),
      Asset(assetType, tokeAddress, 0, formatPrice),
      date ? startTime : 0, date ? endTime : 0, salt)
  } else {
    const formatPrice = Number(price)
    sellOrder = assOrder(0, address, ZERO,
      Asset(ERC721, collectionAddress, nft_id, 1),
      Asset(assetType, tokeAddress, '0', formatPrice),
      date ? startTime : 0, date ? endTime : 0, salt)
  }
  return sellOrder
}

export const get_NS_BuyOrder = async ({
  collectionAddress,
  nft_id,
  type, price, date, isChain, tokeAddress, sellAddress, salt,
}) => {
  let startTime
  if (type === BUY) {
    startTime = 0
  } else {
    startTime = Math.floor(new Date().getTime() / 1000)
  }
  const validity = 60 * 60 * 24 * date
  const endTime = startTime + validity
  const signer = await getSigner()
  if (!signer) return {}
  const address = await signer.getAddress()
  if (!address) return {}
  const assetType = tokeAddress === ETH_ADDRESS ? ETH : ERC20
  let buyOrder
  if (isChain) {
    // 这里需要将给的nft_id转成数字类型,如果数字太大的话，会导致签名报错
    // const tokeID = Number(nft_id)
    const formatPrice = utils.parseEther(String(price))
    buyOrder = assOrder(1, address, sellAddress,
      Asset(ERC721, collectionAddress, nft_id, 1),
      Asset(assetType, tokeAddress, 0, formatPrice), startTime, date ? endTime : 0, salt)
  } else {
    const formatPrice = Number(price)
    buyOrder = assOrder(1, address, sellAddress,
      Asset(ERC721, collectionAddress, nft_id, 1),
      Asset(assetType, tokeAddress, '0', formatPrice),
      startTime, date ? endTime : 0, salt)
  }
  return buyOrder
}

export const get_OS_SellOrder = async ({
  // 卖方地址
  date,
  collectionAddress,
  nft_id,
  price,
  fees,
}) => {
  const provider = new ethers.providers.JsonRpcProvider(
    'https://eth-mainnet.g.alchemy.com/v2/xxbUOtou8e3pa2MEmatbsf6ovVlLVYas'
  )

  const seaport = new Seaport(provider)
  const startTime = Math.floor(new Date().getTime() / 1000)
  const endTime = startTime + 60 * 60 * 24 * date
  const signer = await getSigner(true)
  if (!signer) return {}
  const offerer = await signer.getAddress()
  if (!offerer) return {}
  const order = {
    startTime: startTime.toString(),
    endTime: endTime.toString(),
    offer: [
      {
        itemType: 2,
        token: collectionAddress,
        identifier: nft_id,
      },
    ],
    consideration: [
      {
        // 上架金额
        // amount: ethers.utils.parseEther('1').toString(),
        amount: ethers.utils.parseEther(`${price}`).toString(),
        // 卖方地址
        recipient: offerer,
      },
    ],
    allowPartialFills: false,
    restrictedByZone: false,
    fees: [
      ...fees,
    ],
    zone: '0x004C00500000aD104D7DBd00e3ae0A5C00560C00',
    conduitKey: '0x0000007b02230091a7ed01230072f7006a004d60a8d4e71d599b8104250f0000',
  }
  const sellOrder = await seaport.createOrder(order, offerer)
  return sellOrder
}

export const get_OS_BuyOrder = async (sell_OS_order) => {
  const seaportOrders = {
    created_date: '2023-02-03T09:12:43.333446',
    closing_date: '2023-08-02T09:09:11',
    listing_time: 1675415351,
    expiration_time: 1690967351,
    order_hash: '0x88c7758e56ead95408190a3627264fa6efcc75689365fff72ab15e7ad8418d90',
    protocol_data: sell_OS_order,
    protocol_address: '0x00000000000001ad428e4906aE43D8F9852d0dD6',
  }

  const signer = await getSigner(true)
  const buyAddress = await signer.getAddress()

  const { advancedOrder, value } = getAdvancedOrderParams(seaportOrders)
  const seaportInterface = new ethers.utils.Interface(SEAPORT_ABI)
  console.log(seaportInterface, 'seaportInterface---')
  console.log(signer, 'signer--')
  const calldata = seaportInterface.encodeFunctionData('fulfillAdvancedOrder', [
    advancedOrder,
    [],
    OPENSEA_CONDUIT_KEY,
    buyAddress,
  ])

  const openseaValue = value
  const planner = new RoutePlanner()
  planner.addCommand(SEAPORT, [value.toString(), calldata])
  planner.addCommand(SWEEP, [ETH_ADDRESS, buyAddress, 0])
  const { commands, inputs } = planner

  return { commands, inputs, openseaValue }

  // const router = await CONTRACT.get_Trade_Instance()
  // console.log(commands, inputs, DEADLINE, 'params----')
  // const receipt = await (
  //   await router['execute(bytes,bytes[],uint256)'](commands, inputs, DEADLINE, {
  //     value: openseaValue,
  //   })
  // ).wait()
  // console.log('receipt:', receipt)
}

export const get_XY_SellOrder = async ({
  collectionAddress,
  nft_id,
  price,
  expirationTime, //过期时间 Math.round(Date.now() / 1000) + date * 24 * 60 * 60,
  isChangePrice = false, //是否是降价操作
}) => {
  console.log('get_XY_SellOrder')
  // 金额需要转换
  const _price = ethers.utils.parseEther(`${price}`).toString()
  const signer = await getSigner(true)
  if (!signer) return {}
  const sellOrder = await getXYSellOrder({
    signer,
    tokenAddress: collectionAddress,
    tokenId: nft_id,
    price: _price,
    expirationTime,
    isChangePrice,
  })
  return sellOrder
}

export const get_XY_BuyOrder = async () => {
  console.log('get_XY_BuyOrder')
}

export const get_LK_SellOrder = async ({
  date,
  collectionAddress,
  nft_id,
  price,
}) => {
  const signer = await getSigner()
  if (!signer) return {}
  const address = await signer.getAddress()
  if (!address) return
  const chainId = SupportedChainId.MAINNET
  const addresses = addressesByNetwork[chainId]

  // 拿nonce

  const res = await get_LK_Nonce({
    market: 3,
    caller: addresses,
  })
  console.log(res, 'nonce res--')

  const now = Math.floor(Date.now() / 1000)
  const paramsValue = []

  // Get protocolFees and creatorFees from the contracts
  // 怎么拿？
  const netPriceRatio = BigNumber.from(10000).sub(protocolFees.add(creatorFees))
    .toNumber()
  // This variable is used to enforce a max slippage of 25% on all orders, if a collection change the fees to be >25%, the order will become invalid
  const minNetPriceRatio = 7500
  const validity = 60 * 60 * 24 * date

  console.log('get_LK_SellOrder')
  const makerOrder = {
    isOrderAsk: true,
    signer: signerAddress,
    collection: collectionAddress,
    price: utils.parseEther(String(price)).toString(), // :warning: PRICE IS ALWAYS IN WEI :warning:
    tokenId: nft_id, // Token id is 0 if you use the STRATEGY_COLLECTION_SALE strategy
    amount: '1',
    strategy: addresses.STRATEGY_STANDARD_SALE,
    currency: addresses.WETH,
    nonce: nonce.toNumber(),
    startTime: now,
    endTime: now + validity,
    minPercentageToAsk: Math.max(netPriceRatio, minNetPriceRatio), // Alternatively, just set it to 9800
    params: paramsValue,
  }
  return makerOrder
}

export const get_LK_BuyOrder = async () => {
  console.log('get_LK_BuyOrder')
}

export const getTradeSellOrder = {
  [Nswap]: get_NS_SellOrder,
  [Opensea]: get_OS_SellOrder,
  [Looksrare]: get_LK_SellOrder,
  [X2Y2]: get_XY_SellOrder,
}
export const getTradeBuyOrder = {
  [Nswap]: get_NS_BuyOrder,
  [Opensea]: get_OS_BuyOrder,
  [Looksrare]: get_LK_BuyOrder,
  [X2Y2]: get_XY_BuyOrder,
}