
// AssetType资产的分类
// const ETH = id("ETH");
// const ERC20 = id("ERC20");
// const ERC721 = id("ERC721");
// const ERC1155 = id("ERC1155");
// const CRYPTO_PUNKS = id("CRYPTO_PUNKS");
// token地址，类似Collection在ERC721
// tokenID 在nft类型中会用到，类似指代item
function AssetType(assetClass, tokenAddress, tokenID) {
  return { assetClass, tokenAddress, tokenID }
}

function Asset(assetClass, tokenAddress, tokenID, value) {
  return { assetType: AssetType(assetClass, tokenAddress, tokenID), value }
}
// side: 表示nft的买方还是卖方 0:卖方 1: 买方
// saleKind: 交易的类型 0: 固定价格
// maker: order的创建者
// taker: 交易的对手方，和maker对应
// nft: 描述nft资产信息
// price: nft价格信息
// salt: 随机数，保证订单唯一性
// start: 订单开始时间
// end: 订单结束时间
// version: 版本信息，order版本
// extra: 额外信息

function Order(side, saleKind, maker, taker, nft, price, salt, start, end, version, extra) {
  return { side, saleKind, maker, taker, nft, price, salt, start, end, version, extra }
}

const Types = {
  AssetType: [
    { name: "assetClass", type: "bytes4" },
    { name: "tokenAddress", type: "address" },
    { name: "tokenID", type: "uint256" },
  ],
  Asset: [
    // 资产类型，不一定是nft,也有可能是price
    { name: "assetType", type: "AssetType" },
    // 资产的数量

    { name: "value", type: "uint256" },
  ],
  Order: [
    { name: "side", type: "uint8" },
    { name: "saleKind", type: "uint8" },
    { name: "maker", type: "address" },
    { name: "taker", type: "address" },
    { name: "nft", type: "Asset" },
    { name: "price", type: "Asset" },
    { name: "salt", type: "uint256" },
    { name: "start", type: "uint256" },
    { name: "end", type: "uint256" },
    { name: "version", type: "bytes4" },
    { name: "extra", type: "bytes" },
  ],
}

async function sign(order, account, verifyingContract) {
  const chainId = Number(await account.getChainId())

  return account._signTypedData({
    name: "Nswap",
    version: "1",
    chainId,
    verifyingContract,
  }, Types, order)
}

export {
  AssetType,
  Asset,
  Order,
  sign,
  Types,
}