import React, { Fragment } from 'react'
import styles from '@/assets/Header.module.scss'
import { DEFAULT_COLLECTION } from '@utils/img'
import { imgOnError, getChainName } from '@/utils/utils'
import { logoUrl_0_3_3 } from './constants'

const SearchItem = (props) => {
  const { title, data, imgUrlKey } = props

  const handleRedirect = (item) => {
    const { contract_address, token_id } = item || {}
    const chainName = getChainName()
    switch (title) {
      case 'Collections':
        location.href = `/collection/${chainName}/${item.address}`
        break
      case 'Launchpad':
        location.href = `/launch/${item.project_id}`
        break
      case 'Accounts':
        location.href = `/user/${item.address}`
        break
      case 'Items':
        location.href = `/item/${chainName}/${contract_address}/${token_id}`
        break
      default:
        break
    }
  }

  return (
    <>
      <div className={styles.search_title}>
        <span>{title}</span>
      </div>
      {data?.map((item, index) => {
        return (
          <Fragment key={index}>
            <div className={styles.search_item} onClick={() => handleRedirect(item)}>
              <img
                className={styles.item_img}
                src={item[imgUrlKey] || DEFAULT_COLLECTION}
                width={30}
                height={30}
                onError={imgOnError}
                style={{ marginLeft: '16px', borderRadius: '4px' }}
                alt=""
              />
              <span>{item.name}</span>
              {['Collections'].includes(title) && item?.auth === 1 ? (

                <img
                  className={styles.authentication}
                  src={`${logoUrl_0_3_3}auth_icon_3x.png`}
                  alt=""
                  width={10}
                  height={10}
                />
              ) : null}
            </div>
          </Fragment>
        )
      })}
    </>
  )
}

export default SearchItem