import { BigNumber, BigNumberish, ethers } from 'ethers'
import bn from 'bignumber.js'

export const ETH_ADDRESS = ethers.constants.AddressZero
export const DEADLINE = 2000000000
export const OPENSEA_CONDUIT_KEY = '0x0000007b02230091a7ed01230072f7006a004d60a8d4e71d599b8104250f0000'

export function expandTo18DecimalsBN(n) {
  // use bn intermediately to allow decimals in intermediate calculations
  return BigNumber.from(new bn(n).times(new bn(10).pow(18))
    .toFixed())
}

export function calculateValue(considerations) {
  return considerations.reduce(
    (amt, consideration) => amt.add(consideration.startAmount),
    expandTo18DecimalsBN(0)
  )
}

export function getAdvancedOrderParams(apiOrder) {
  try {
    delete apiOrder.protocol_data.parameters.counter
    const advancedOrder = {
      parameters: apiOrder.protocol_data.parameters,
      numerator: BigNumber.from('1'),
      denominator: BigNumber.from('1'),
      signature: apiOrder.signature,
      extraData: '0x00',
    }
    const value = calculateValue(apiOrder.protocol_data.parameters.consideration)
    return { advancedOrder, value }
  } catch (error) {
    return {}
  }
}

