/**
 * 检查链是否正确弹窗，主要用于重要交易前置，判断链是否正确，或者是否可用
 */
/* eslint-disable max-len */
import React, { useCallback } from 'react'
import { Modal, Button } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { setSwitchMarketChain, setUnavailableChain, setVisitChain } from '@/slices/chainSlice'

import styles from './index.module.scss'

function CheckChainModal() {
  const dispatch = useDispatch()
  const { switchMarketChain, unavailableChain, realChain, visitChain } = useSelector(state => state.chain)

  //切市场chain
  const handleSwitchMarketChain = useCallback(() => {
    dispatch(setVisitChain(realChain?.chainId))
    dispatch(setSwitchMarketChain(false))
  }, [realChain])

  //不可用链
  const handleOk = useCallback(() => {
    dispatch(setUnavailableChain(false))
  }, [])

  return (
    <>
      <Modal open={switchMarketChain.show}>
        <div className={styles.container}>
          <div className={styles.content}>
            <div className={styles.header}>
              Switch Chain Market
              <img onClick={() => dispatch(setSwitchMarketChain(false))} className={styles.close} src="https://s3-nswap-base.nswap.com/0.3.3/close_icon_new_3x.png"/>
            </div>
            <div className={styles.body}>
              <div className={styles.img_box} >
                <img src={realChain?.logo} alt=""/>
              </div>
              The {realChain?.chainName_full} Chain of the NFT you currently select does not match the chain market {visitChain?.chainName_full},
              please switch to {realChain?.chainName_full} Chain market.
            </div>
            <div className={styles.footer}>
              <Button onClick={handleSwitchMarketChain} className={styles.btn} variant="contained">Switch to {realChain?.chainName_full} Chain market</Button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal open={unavailableChain.show}>
        <div className={styles.container}>
          <div className={styles.content}>
            <div className={styles.body}>
              iPollo chain is currently on testnet. Nswap will support trading and transferring soon.
            </div>
            <div className={styles.footer}>
              <Button onClick={handleOk} className={styles.btn} variant="contained">OK</Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default CheckChainModal