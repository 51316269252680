import { ethers } from 'ethers';
import getSigner from './getSigner.js';
import getDefaultProvider from './getDefaultProvider.js';
import XY_CANCEL_ABI  from '@contract/ABI/XY_CANCEL.json'
import { TRADE_XY_MAIN_ADDRESS } from '@contract/Address'

const get_OS_Instance = async (readOnly = false) => {
    
    const provider = await getDefaultProvider()
    if (!readOnly) {
        const signer = await getSigner(true)
        if (!signer) return
        return new ethers.Contract(TRADE_XY_MAIN_ADDRESS, XY_CANCEL_ABI, signer);
    }
    return new ethers.Contract(TRADE_XY_MAIN_ADDRESS, XY_CANCEL_ABI, provider)
}
export default get_OS_Instance;
