import React, { useState, useEffect, useRef, useMemo } from 'react'
import { AppBar, Badge } from '@mui/material'
import { useRouter } from 'next/router'
import useMediaQuery from '@mui/material/useMediaQuery'
import classNames from 'classnames'
import Select from '@/components/Select'
import Cart from '@components/cart/Cart.jsx'
import CartApprove from '@components/cart/CartApprove.jsx'
import CartFeedback from '@components/cart/CartFeedback.jsx'
import { useAppContext } from '@/utils/context'
import { useSelector } from 'react-redux'
import useConnect from '@/hooks/useConnect'
import { useAccount } from 'wagmi'
import useListenConnectionEvent from '@/hooks/useListenConnectionEvent'

import styles from '@/assets/Header.module.scss'

import { SHOP_CART } from '@utils/img'
import { getChainName } from '@utils/utils'

import BasicTabs from './Tabs.jsx'
import ChainSelect from './ChainSelect'
import SearchBar from './SearchBar'
import MMemu from './MMemu'

import { logoUrl_0_3_3, TOP_LOGO, getSecondaryMenu } from './constants'

const Header = () => {
  const { visitChainId } = useSelector(state => state.chain)
  const { userInfo } = useSelector(state => state.common)
  const router = useRouter()
  const [tabValue, setTabValue] = useState('')
  const [defaultValue, setDefaultValue] = useState('')
  const [isNoBoxShadow, setIsNoBoxShadow] = useState(false)
  const { connect, disconnect } = useConnect()
  const { isConnected } = useAccount()
  useListenConnectionEvent()

  const {
    state: { cartState },
    cartDispatch,
  } = useAppContext()

  const match = useMediaQuery('(min-width: 1200px)')

  const prePathname = useRef('')
  const mounted = useRef(false)

  useEffect(() => {
    const { pathname = '' } = router || {}
    setIsNoBoxShadow((
      pathname.includes('/market') ||
      pathname === '/'
    ) && match)
  }, [router.pathname, match])

  useEffect(() => {
    if (
      mounted.current &&
      location.pathname !== prePathname.current
    ) {
      const { pathname } = location
      prePathname.current = pathname
      setTabValue(pathname.replace(/\//g, '') || '')
    }
  })

  const handleClickHeaderMenu = (item) => {
    item.method && item.method()
    if (item.url )window.location.href = `${window.location.origin}${item.url}`
  }

  const mySecondaryMenu = useMemo(() => {
    return getSecondaryMenu(disconnect)
  }, [disconnect])

  //当前链下的购物车数量
  const currentCart = useMemo(() => {
    return cartState?.cart?.filter(item => item.chainId === visitChainId)
  }, [cartState?.cart, visitChainId])

  return (
    <>
      <AppBar position="sticky" className={classNames(styles.appbar, isNoBoxShadow ? styles.appbar_border : '')}>
        {match ? (
          <nav className={styles.header_box}>
            <div className={styles.header_logo} onClick={() => {
              setTabValue('')
              router.push(`/${getChainName()}`)
            }}
            >
              <img src={TOP_LOGO} height={72} width={136} alt="" />
            </div>
            <>
              <SearchBar tabValue={tabValue}/>
              <div className={styles.header_right}>
                <BasicTabs
                  match
                  value={tabValue}
                  onTabChange={(value) => {
                    setTabValue(value)
                    setDefaultValue(new Date().getTime())
                  }}
                />
                <ChainSelect/>
                <Badge
                  badgeContent={currentCart?.length}
                  color="primary"
                >
                  <div className={styles.header_connect}
                    onClick={() => {
                      cartDispatch({ type: 'visible', payload: true })
                    }}
                  >
                    <img src={SHOP_CART} alt="" />
                  </div>
                </Badge>
                {!isConnected ? (
                  <div className={styles.header_connect} onClick={() => {
                    connect()
                  }}
                  >
                    <img src={`${logoUrl_0_3_3}wallet.png`} alt="" />
                  </div>
                ) : (
                  <div className={styles.avatar_wrap}>
                    <Select
                      normal
                      defaultValue={defaultValue}
                      header={
                        userInfo?.user?.avatar_uri ? (
                          <div className={styles.userInfoBox}>
                            <img
                              className={styles.header_avatar}
                              src={userInfo?.user?.avatar_uri}
                              width={32}
                              height={32}
                              alt=""
                            />
                            <span className={styles.address}>{userInfo?.user?.address?.slice(0, 6)}</span>
                          </div>
                        ) : <span style={{ display: 'inline-block', height: 32, width: 32 }}/>
                      }
                      isHeader
                      options={mySecondaryMenu}
                      onChange={handleClickHeaderMenu}
                    />
                  </div>
                )}
              </div>
            </>
          </nav>
        ) : null}

        {
          !match && (
            <MMemu
              tabValue={tabValue}
              currentCartNum={currentCart?.length}
              cartDispatch={cartDispatch}
            />
          )
        }
      </AppBar>

      <Cart currentCart={currentCart} chainId={visitChainId}/>
      <CartApprove currentCart={cartState?.finalCart} />
      <CartFeedback currentCart={cartState?.finalCart}/>
    </>
  )
}

export default Header
