import config from '@/config/config'
import { getChainOptions } from '@/utils/networks.js'

export const logoUrl_0_3_3 = `${config.assetsUrl}0.3.3/`

export const AVATAR = 'https://s3-nswap-base.nswap.com/avatar.png'
export const TOP_LOGO = 'https://s3-nswap-base.nswap.com/0.3.3/ns_logo@3x.png'

export const ignoreHost = ['localhost', '127.0.0.1']

export const headerNavs = [{
  label: 'Launchpad',
  icon: 'launchpad_icon.png',
  url: '/launchpad',
}, {
  label: 'Lucky',
  icon: 'raffle_icon.png',
  url: '/raffles',
},
//  {
//   label: 'Market',
//   icon: 'market_icon.png',
//   url: '/market',
// },
{
  label: 'Rankings',
  icon: 'rankings_icon.png',
  url: '/rankings',
}, {
  label: 'My space',
  icon: 'user_icon@3x.png',
  url: '/user',
},
{
  label: 'Community',
  icon: 'bbs_icon@3x.png',
  url: '/community',
}, {
  label: 'Explore',
  icon: 'metatwit_icon@3x.png',
  url: '/explore',
},
]

//获取切链options
export const getMainChainOptions = () => {
  return getChainOptions().map((item, index) => {
    return {
      key: index,
      label: item?.label,
      value: item?.value,
      url: item.source?.url,
    }
  })
}

const toCommunity = () => {
  const address = localStorage.getItem('address')
  if (address === 'null') {
    showSnackBar({ type: 'error', message: 'Please sign in.' })
  } else {
    window.open(`https://bbs.nswap.com/User/Login?address=${address}`)
  }
}

//个人中心二级菜单
export const getSecondaryMenu = (disconnect) => {
  return [{
    label: 'My Space',
    value: 'My Space',
    url: '/user',
  }, {
    label: 'Community',
    value: 'Community',
    method: toCommunity,
  }, {
    label: 'User Settings',
    value: 'User Settings',
    url: '/user/setting',
  }, {
    label: 'Import',
    value: 'Import',
    url: '/import',
  }, {
    label: 'Logout',
    value: 'Logout',
    method: disconnect,
  }]
}