import { isProduction } from '@utils/utils'
import { Nswap, Opensea, Looksrare, X2Y2 } from '@/utils/constant'

export const apiKey = "https://eth-rinkeby.alchemyapi.io/v2/KJSu0VvS4R2I3j7mGiW-LySOdDqrh5L9"
export const DAI_ADDRESS = "0x6A9865aDE2B6207dAAC49f8bCba9705dEB0B0e6D"
export const EXCHANGE_ADDRESS = "0xc9aa4f2dcd44ecf28193f737ba8d6fed44faedbe"
export const EXCHANGE_ADDRESS_BATCH = "0xc9aa4f2dcd44ecf28193f737ba8d6fed44faedbe"

export const EXCHANGE_MAIN_ADDRESS = "0x080fa1fb48E0b1Bd251348efd02c1e7a12A931ac"
export const NFT_ADDRESS = "0x4F2836c502c973EdeA8697B369c9d6ae4c1f074B"
export const ETH_ADDRESS = "0x0000000000000000000000000000000000000000"
export const ZERO = "0x0000000000000000000000000000000000000000"
export const WETH_ADDRESS = "0xc778417E063141139Fce010982780140Aa0cD5Ab"
export const WETH_MAIN_ADDRESS = "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2"
// 聚合交易分发地址
export const TRADE_AMOUNT_MAIN_ADDRESS = '0x517DDA561A0Ab890e328C3cAdEc59bCa5B74865c'

export const TRADE_NS_TEST_ADDRESS = '0x080fa1fb48E0b1Bd251348efd02c1e7a12A931ac'
export const TRADE_NS_MAIN_ADDRESS = '0x080fa1fb48E0b1Bd251348efd02c1e7a12A931ac'

export const TRADE_OP_TEST_ADDRESS = '0x00000000000000adc04c56bf30ac9d3c0aaf14dc' //opensea 1.5 版本
export const TRADE_OP_MAIN_ADDRESS = '0x00000000000000adc04c56bf30ac9d3c0aaf14dc'
export const TRADE_OP_V14_TEST_ADDRESS = '0x00000000000001ad428e4906aE43D8F9852d0dD6' //opensea 1.4 版本
export const TRADE_OP_V14_MAIN_ADDRESS = '0x00000000000001ad428e4906aE43D8F9852d0dD6'
export const TRADE_OP_V1_TEST_ADDRESS = '0x00000000006c3852cbEf3e08E8dF289169EdE581'//opensea 1.0 版本
export const TRADE_OP_V1_MAIN_ADDRESS = '0x00000000006c3852cbEf3e08E8dF289169EdE581'

export const TRADE_LS_TEST_ADDRESS = '0x59728544B08AB483533076417FbBB2fD0B17CE3a'
export const TRADE_LS_MAIN_ADDRESS = '0x59728544B08AB483533076417FbBB2fD0B17CE3a'
export const TRADE_XY_MAIN_ADDRESS = '0x74312363e45DCaBA76c59ec49a7Aa8A65a67EeD3' 
export const TRADE_XY_TEST_ADDRESS = '0x74312363e45DCaBA76c59ec49a7Aa8A65a67EeD3' 

export const XY_APPROVE_ADDRESS = '0xf849de01b080adc3a814fabe1e2087475cf2e354' 
export const OS_ARRPROVE_ADDRESS = '0x1e0049783f008a0085193e00003d00cd54003c71'

export const BEST_MARKET = {
  0: Nswap,
  1: Opensea,
  2: Looksrare,
  3: X2Y2,
}

export const TRADE_MARKET_MAIN_ADDRESS = {
  [Nswap]: TRADE_NS_MAIN_ADDRESS,
  [Opensea]: TRADE_OP_MAIN_ADDRESS,
  [Looksrare]: TRADE_LS_MAIN_ADDRESS,
  [X2Y2]: TRADE_XY_MAIN_ADDRESS,
}

export const TRADE_MARKET_TEST_ADDRESS = {
  [Nswap]: TRADE_NS_TEST_ADDRESS,
  [Opensea]: TRADE_OP_TEST_ADDRESS,
  [Looksrare]: TRADE_LS_TEST_ADDRESS,
  [X2Y2]: TRADE_XY_TEST_ADDRESS,
}


// mint 专用地址
export const NFT_IPOLLO_ADDRESS = "0x31B4c644751fc8A789EAda9c9Ed1F258Dc9258DB" // 内部原生 mint 合约地址（接近废弃）
// 标准的合约地址（abi默认用的是 MINT_ABI_TWO），换地址前需要问合约开发者清楚是第几阶段
export const NFT_IPOLLO_ADDRESS_V2 = "0xfE542c1FEcc88f1d8B0E53171B8E4d486391fb98"

// 疯狂蜗牛
// export const TEST_ADDRESS = "0xC13788BdddbB82678E4375368A8E233dD9eAb6FC"
// export const SNAILI_ADDRESS = "0x65c5963C13e1d4F14534cF453F5b0512e7d523AB"
// export const POTIONS_ADDRESS = "0xdF9D108F07f005AECEF787954e3961E7C881Fa55"
export const SNAILI_ADDRESS = "0x568251b2EC5f2e58BCf16c4b0aC3C876A392A9b4"
export const POTIONS_ADDRESS = "0x00251046f3AB7e35B97c358E799A5fAA8E99d97D"
export const CRAZY_SNAILI = "0x20255bDe9AF78c3A1ef3c1A5ca46AD19671AA9fA"

// 合成机器人

export const BSC_ROBOT_MAIN_ADDRESS = '0xAeef2b7D89b609b2890e10413D13177fC9301Ee6'
export const BSC_ROBOT_TEST_ADDRESS = '0xEc585c703af9C80C82c1FA86e73F279D1c3a32e9'

// 能量核心
export const ENERGY_MAIN_ADDRESS = '0xBC867E76D1360b2D21eB1C2753f94b1C103415e1'
export const ENERGY_TEST_ADDRESS = '0x0FAcFC73F66aa8F019000833Eb6065e89C197658'
export const LEGGI_MAIN_ADDRESS = '0xfE542c1FEcc88f1d8B0E53171B8E4d486391fb98'
export const LEGGI_TEST_ADDRESS = '0x5F5dCE07903b0b9854599De6A7c38e40cf573760'


// 第三方ethw项目地址
export const OTHER_ETHW_CONTRACT_ADDRESS = '0x532b75347CDFd7E0C48148396Dabf6071529B2Ec'

// BADGE_ADDRESS
export const BADGE_ADDRESS = '0xA3E80dD8c920f3bE378BD48096F12FD0406EC9b5'
// 币安正式合约
export const BSC_EXCHANGE_MAIN_ADDRESS = '0x080fa1fb48e0b1bd251348efd02c1e7a12a931ac'
// 币安测试合约
export const BSC_EXCHANGE_TEST_ADDRESS = '0xEf71d2088f24002822Eabc8E4b8d8e1e7ebA79Ab'
// ipo
export const IPO_EXCHANGE_MAIN_ADDRESS = '0x318C37ee4a61de98E045f7B529F1D91574ABE6c6'
export const IPO_EXCHANGE_TEST_ADDRESS = '0x318C37ee4a61de98E045f7B529F1D91574ABE6c6'

export const BSC_EXCHANGE_TEST_ADDRESS_BATCH = '0xEf71d2088f24002822Eabc8E4b8d8e1e7ebA79Ab'

/**
 * 里程碑正式活动地址
 * */ 
export const COMPAIGN_ADDRESS = isProduction() ? '0xD24A3f35b71aC2e2331C02A55d55Ccb7DD9aE979' : '0xf2f85b40dDF3Bce1595484020bCF498925b57Cf1'

/************** mint相关地址 start *******************/
/**
 * 1、目前暂时只有 2 两套逻辑，但是支持多个 mint 同时存在（先前端做好映射，等规范有了，再把这些配置后台化）
 * 2、只需 2 步走，填写地址与abi映射关系，填写两套逻辑所包含的地址
 * 3、第三方需要而外逻辑的，另行添加，比如 MINT_TEHW_OTHER_ADDRESS
 */

//1、 mint 相关地址和 abi 名称的映射，如果合约地址使用新的 abi，需要创建新的 abi 文件，名称一一对应。
const ELEPHANT_ADDRESS = '0xC0a91820D36DD0714508165cc94C436f1c4C7F50'
const OOC_ADDRESS = '0xBB1B95F7a7083426C92B62BD8c58897dFb734198' // zeroX地址
const REPORT_ADDRESS = '0xCc7506538397D015881A6f29731628f9171F230E'

export const MINT_ADDRESS_MAP  =  {
  [NFT_IPOLLO_ADDRESS]: 'MINT_ABI',
  [NFT_IPOLLO_ADDRESS_V2]:'MINT_ABI_TWO',
  [OTHER_ETHW_CONTRACT_ADDRESS]:'Third_ABI',
  '0xf9628fEFd388b50d25131b30D6Ae13552c89B9ab': 'IPOLOO_ABI', // ipollo正式合约
  '0xcB7A5EFbF5dc03d3c26578BeB13E839C1f3e3E6a': 'MINT_ABI_TWO', // goSpace正式合约
  '0xCA22ba3D0029CCdeAF351393Bd40D5F51959118F': 'PEAKBOY_ABI', // peakboy 正式合约
  [COMPAIGN_ADDRESS]: 'ACTIVITY_ABI', // mint活动合约
  '0x12E98bCe4f9B413BA6E7AE98653Cf00a71fc696c': 'MINT_7140_ABI', // 7140 测试合约
  [ELEPHANT_ADDRESS]: 'Elephant_ABI', // 特朗普mint 合约
  [OOC_ADDRESS]: 'OOC_ABI',
  [REPORT_ADDRESS]: 'ACTIVITY_ABI'
}

//2、前端定制点，如果这次 mint 命中某个规则，只需要把地址填入即可
/**
 * 官方原生 mint 地址, 如果需要存在多个标准的合约，往下面的数组添加
*/
export const OFFICIAL_MINT_ADDRESS = [
  NFT_IPOLLO_ADDRESS,
  NFT_IPOLLO_ADDRESS_V2,
  '0xcB7A5EFbF5dc03d3c26578BeB13E839C1f3e3E6a',
]

/**
 * 第三方原生 mint 地址
*/
export const OTHER_MINT_ADDRESS = [
  OTHER_ETHW_CONTRACT_ADDRESS,
  '0xf9628fEFd388b50d25131b30D6Ae13552c89B9ab',
  '0xCA22ba3D0029CCdeAF351393Bd40D5F51959118F',
  '0x12E98bCe4f9B413BA6E7AE98653Cf00a71fc696c'
]

export const ACTIVITY_MINT_ADDRESS = [
  COMPAIGN_ADDRESS
]

export const ELEPHANT_MINT_ADDRESS = [
  ELEPHANT_ADDRESS
]

export const OOC_MINT_ADDRESS = [
  OOC_ADDRESS
]

/**
 * free mint  //TODO 之后作为一个后台的定制点
 * - 只展示公售
 * - 无上限
 */
export const ONLY_PUBLIC_MINT = [
  NFT_IPOLLO_ADDRESS_V2,
  '0xcB7A5EFbF5dc03d3c26578BeB13E839C1f3e3E6a',
  '0xCA22ba3D0029CCdeAF351393Bd40D5F51959118F',
  COMPAIGN_ADDRESS,
  '0x12E98bCe4f9B413BA6E7AE98653Cf00a71fc696c',
  ELEPHANT_ADDRESS,
  OOC_ADDRESS,
]

export const ONLY_PRE_MINT = [
  '0xCA22ba3D0029CCdeAF351393Bd40D5F51959118F',
  '0xf9628fEFd388b50d25131b30D6Ae13552c89B9ab',
  ELEPHANT_ADDRESS,
  OOC_ADDRESS
]

/**************  mint相关地址 end **********************/  