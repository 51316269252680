import { useCallback } from 'react'

import { useDisconnect } from 'wagmi'
import { useConnectModal } from '@rainbow-me/rainbowkit'
import { setItem, removeItem, getItem } from '@/utils/utils'

const useConnect = () => {
  const disconnectFn = useDisconnect()
  const { openConnectModal } = useConnectModal()

  //使用rainbowkit的登录弹窗
  const connect = useCallback(() => {
    typeof openConnectModal === 'function' && openConnectModal()
  }, [openConnectModal])

  const disconnect = useCallback(
    async function () {
      disconnectFn.disconnect()

      localStorage.setItem('address', null)
      // setAnchorEl(null)
      removeItem('userInfo')
      // 清除合成机器人的ID缓存
      localStorage.setItem('expiredItem', JSON.stringify({ energyId: [], robotPrartId: [] }))

      location.reload()
      return false
    },
    [disconnectFn]
  )

  return { connect, disconnect }
}

export default useConnect
