//监听账户登录，主要是解决钱包登录之后，需要调用后台接口，形成完整登录
import { useEffect } from 'react'
import { ethers } from 'ethers'
import { setItem, getItem } from '@/utils/utils'
import { useAccount } from 'wagmi'
import useConnect from '@/hooks/useConnect'
import { login } from '@/api'
import { setUserInfo } from '@/slices/commonSlice'
import { useDispatch } from 'react-redux'

const useListenConnectionEvent = () => {
  const { address, isDisconnected } = useAccount()
  const { disconnect } = useConnect()
  const dispatch = useDispatch()

  //切换账户，刷新页面
  useEffect(() => {
    const localAddress = localStorage.getItem('address') || ''
    if (ethers.utils.isAddress(localAddress) && address && localAddress !== address) {
      window.location.reload()
    }
  }, [address])

  //监听到用起拿包登录退出的
  useEffect(() => {
    const userInfo = getItem('userInfo')
    if (userInfo.user?.address && isDisconnected) {
      disconnect()
    }
  }, [isDisconnected])

  useEffect(() => {
    const checkLogin = async() => {
      let userInfo = getItem('userInfo')
      const expiresTimeStr = userInfo.expiresTime || ''
      const curDateTimeStr = new Date().getTime()

      if (
        !userInfo ||
        !userInfo.expiresTime ||
        (curDateTimeStr > expiresTimeStr) ||
        userInfo?.user?.address !== address
      ) {
        const res = await login({ address })
        let date = new Date()
        date = date.setDate(date.getDate() + 1)
        const dateTimeStr = new Date(date).getTime()
        userInfo = res?.data || {}
        userInfo.expiresTime = dateTimeStr
        setItem('userInfo', userInfo)
        dispatch(setUserInfo(userInfo))
      } else {
        dispatch(setUserInfo(userInfo))
      }
    }

    if (ethers.utils.isAddress(address)) {
      localStorage.setItem('address', address)
      checkLogin()
    }
  }, [address])
}

export default useListenConnectionEvent
