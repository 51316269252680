import { ethers } from 'ethers';
import { DAI_ADDRESS } from '../Address.js'
import DAI_ABI from '../ABI/DAI.json';
import getSigner from './getSigner.js';
// import { getEnv } from '@utils/utils.js';
// import { EnvType } from '@utils/constant.js';
import { isProduction } from '@utils/utils'


const getDaiInstance = async () => {
    // const env = getEnv()
    const address = isProduction() ? DAI_ADDRESS : DAI_ADDRESS
    const signer = await getSigner()
    if (!signer) return
    return new ethers.Contract(address, DAI_ABI, signer);
}
export default getDaiInstance;
