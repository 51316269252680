import { ethers } from 'ethers';
import CrazyABI from '../ABI/TEST.json'
import getSigner from './getSigner.js';
import { CRAZY_SNAILI } from '@contract/Address'

const getTestInstance = async () => {
    const address = CRAZY_SNAILI
    const signer = await getSigner()
    if (!signer) return
    return new ethers.Contract(address, CrazyABI, signer)
}
export default getTestInstance;
