import React from 'react'
import {
  Box,
  Typography,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import Price from '../Price'

import { toNumber } from 'lodash-es'
import { useAppContext } from '@/utils/context'
import { imgOnError } from '@/utils/utils'
import styles from '@/assets/cart/CartFeedback.module.scss'

const CartApprove = ({
  currentCart,
}) => {
  const {
    state: { cartState },
    cartDispatch,
  } = useAppContext()

  const totalPrice = currentCart?.reduce((prev, curr) => toNumber(prev) + toNumber(curr.list_price), 0)

  const handleClose = () => {
    cartDispatch({ type: 'all', payload: { approve: false, loading: false }})
  }

  return (
    <Dialog
      open={cartState?.approve}
      onClose={handleClose}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '550px',
            margin: '25px',
            borderRadius: '16px',
          },
        },
      }}
    >
      <DialogTitle sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        py: '24px',
        fontSize: '20px',
        lineHeight: '30px',
        fontWeight: 600,
      }}
      >
        Approve purchase
        <CloseIcon sx={{ position: 'absolute', right: '24px', cursor: 'pointer' }} onClick={handleClose} />
      </DialogTitle>

      <DialogContent dividers="paper" sx={{ padding: '25px 0 0' }}>
        <DialogContentText>
          <Box className={styles.item} sx={{ padding: '0 25px !important' }}>
            <Box className={styles.imgWrapper}>
              <img src={currentCart?.[0]?.oss_uri} alt="item" onError={imgOnError}/>
            </Box>
            <Box className={styles.info}>
              <Typography
                variant="p"
                component="p"
                className={styles.name}
              >
                {currentCart?.length} {currentCart?.length > 1 ? 'items' : 'item'}
              </Typography>
            </Box>
            <Box>
              <Price
                width={16} height={16}
                price={totalPrice}
                showDollar={true}
                sx={{ textAlign: 'right', fontSize: '16px', fontWeight: 600, lineHeight: '24px' }}
                dollarSx={{ textAlign: 'right' }}
              />
            </Box>
          </Box>

          <Box className={styles.footer}>
            <Box className={styles.tip}>
              <Typography variant="h1">
                Go to your wallet
              </Typography>
              <Typography variant="p">
                {'You\'ll be asked to approve this purchase from your wallet.'}
              </Typography>
            </Box>
          </Box>

        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}

export default CartApprove