import axios from 'axios'
import url from '../config/config'
import { getItem, removeItem } from './utils'
import Router from 'next/router'
import { showSnackBar } from '@components/CustomizedSnackbars'

axios.defaults.timeout = 180000
axios.defaults.baseURL = url.baseUrl

// for dev
axios.interceptors.request.use(
  (config) => {
    const isDev = process.env.NODE_ENV === 'development'
    const isClient = typeof window !== 'undefined'

    if (isDev && config.url.startsWith('/voucher')) {
      console.log('true', config.url)
      return config
    }

    if (isDev && isClient) {
      config.baseURL = '/dev'
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

axios.interceptors.request.use(
  (config) => {
    const userInfo = getItem('userInfo') || {}

    if (config.url.startsWith('https://www.zrx.xyz/')) {
      config.headers = {
        ...config.headers,
        'Content-Type': 'application/json',
      }
    } else {
      config.headers = {
        ...config.headers,
        'Content-Type': 'application/json',
        'session_id': userInfo.token || '',
      }
    }

    // for external api
    if (!!config.params && config.params?.external) {
      delete config.headers.session_id
    }
    return config
  },
  (error) => {
    if (error && error.code === 10004) {
      removeItem('userInfo')
      removeItem('market_loading')
      Router.push('/')
      return
    }
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  (response) => {
    if (response?.data.errCode === 2) {
      // eslint-disable-next-line no-console
      console.log('Expired')
    }
    return response
  },
  (error) => {
    // eslint-disable-next-line no-console
    console.log('Error in request:', error?.response?.data)
    const data = error?.response?.data || {}

    showSnackBar({ type: 'error', message: data?.msg || 'Network Error' })
  }
)

export function setClientUrl() {
  // axios.defaults.baseURL = url.clientUrl;
}

export function get(url, params = {}, config = {}) {
  return new Promise((resolve, reject) => {
    axios.get(url, {
      params: params,
    }, config).then((response) => {
      landing(url, params, response?.data)
      resolve(response?.data)
    })
      .catch((error) => {
        reject(error)
      })
  })
}

export function post(url, data, config = {}) {
  return new Promise((resolve, reject) => {
    axios.post(url, data, config).then(
      (response) => {
        resolve(response?.data)
      },
      (err) => {
        reject(err)
      }
    )
  })
}

export function put(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.put(url, data).then(
      (response) => {
        resolve(response.data)
      },
      (err) => {
        msag(err)
        reject(err)
      }
    )
  })
}

export function deleteAlias(url, params = {}) {
  return new Promise((resolve, reject) => {
    axios.delete(url, {
      params,
    }).then(
      (response) => {
        resolve(response?.data)
      },
      (err) => {
        msag(err)
        reject(err)
      }
    )
  })
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (fecth, url, param) {
  return new Promise((resolve, reject) => {
    switch (fecth) {
      case 'get':
        // eslint-disable-next-line no-console
        console.log('begin a get request,and url:', url)
        get(url, param)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            // eslint-disable-next-line no-console
            console.log('get request GET failed.', error)
            reject(error)
          })
        break
      case 'post':
        post(url, param)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            // eslint-disable-next-line no-console
            console.log('get request POST failed.', error)
            reject(error)
          })
        break
      default:
        break
    }
  })
}

function msag(err) {
  if (err && err.response) {
    switch (err.response.status) {
      case 400:
        alert(err.response.data.error.details)
        break
      case 401:
        alert('Unauthorized. Please log in')
        break

      case 403:
        alert('Access denied')
        break

      case 404:
        alert('Error in request address')
        break

      case 408:
        alert('Request timed out')
        break

      case 500:
        alert('Internal server error')
        break

      case 501:
        alert('Service not implemented')
        break

      case 502:
        alert('Gateway error')
        break

      case 503:
        alert('Service is not available')
        break

      case 504:
        alert('Gateway timed out')
        break

      case 505:
        alert('HTTP version not supported')
        break
      default:
    }
  }
}

function landing(url, params, data) {
  if (data?.code === -1) {
  }
}