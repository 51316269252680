import React from 'react'
import { Typography } from '@mui/material'
import { toNumber } from 'lodash-es'
import { getItem } from '@/utils/utils'
import { WethIcon } from './Icons'
import { getChainLogo } from '@/utils/networks'

const minEthPrice = 0.0001
const Price = (props) => {
  const {
    chainId = Number(getItem('chain')),
    price,
    currency = 1,
    showDollar = false,
    width, height, sx, dollarSx,
  } = props
  const rate = localStorage.getItem('rate')
  const _price = toNumber(price)
  const dollar = toNumber((toNumber(rate) * toNumber(price)).toFixed(2))

  let ethPrice
  if (_price < 1) {
    if (_price < minEthPrice) {
      ethPrice = `< ${minEthPrice}`
    } else {
      ethPrice = _price.toFixed(4)
      ethPrice = toNumber(ethPrice)
    }
  } else {
    ethPrice = _price.toFixed(2)
    ethPrice = toNumber(ethPrice).toLocaleString()
  }

  return (
    <Typography
      variant="p" component="div"
      sx={{
        fontSize: '16px', fontWeight: 600,
        lineHeight: '24px', color: '#333',
        ...sx,
      }}
    >
      {(_price === 0 || isNaN(_price)) ? '-' : (
        <>
          <div style={{ display: 'flex' }}>
            {currency === 1 && <img src={getChainLogo(chainId)} width={24} height={24}/>}
            {currency === 2 && <WethIcon width={width || 8} height={height || 13} sx={{ marginRight: '3px' }} />}
            <div>{ethPrice}</div>
          </div>

          {showDollar && (
            <Typography
              component="div"
              sx={{
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '22px',
                color: '#000',
                opacity: 0.6,
                textAlign: 'center',
                ...dollarSx,
              }}
            >
              { `$${dollar.toLocaleString()}`}
            </Typography>
          )}
        </>
      )}
    </Typography>
  )
}

export default Price