import React, { useEffect, useRef, useState, useMemo } from 'react'
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  useMediaQuery,
  Drawer,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from '@mui/material'
import classNames from 'classnames'
import CloseIcon from '@mui/icons-material/Close'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { remove, cloneDeep, toNumber, filter, isEmpty } from 'lodash-es'
import { ethers, utils } from 'ethers'
import isMobile from 'is-mobile'

import { useAppContext } from '@/utils/context'
import { getItem, imgOnError, setItem, numFormate } from '@/utils/utils'
import { getCartItems, getValidOrders } from '@/api'
import CONTRACT from '@contract/libs/index'
import { assOrder } from '@utils/order'
import { Asset } from '@contract/TEST/order'
import { ETH, ERC20, ERC721 } from '@contract/TEST/assets'
import { errorCaptured, executeContractMethodWithEstimatedGas } from '@contract/utils'
import getSigner from '@contract/libs/getSigner'
import { BUY } from '@utils/constant'
import { ETH_ADDRESS } from '@contract/Address'
import { switchToEthereum } from '@contract/utils'
import { executeSwap } from '@utils/aggregateSwap'
import { OpenseaBan } from '@components/Icons'
import CachedIcon from '@mui/icons-material/Cached'
import { getPendingTxs } from '@/api/trade'
import useConnect from '@/hooks/useConnect'
import { useAccount } from 'wagmi'
import useBalance from '@/hooks/useBalance.js'

import Price from '../Price'
import { showSnackBar } from '../CustomizedSnackbars'

import styles from '@/assets/cart/Cart.module.scss'

const Transition = React.forwardRef(function Transition(
  props,
  ref,
) {
  return <Slide direction="left" ref={ref} {...props} />
})

const Cart = ({
  chainId,
  currentCart,
}) => {
  const {
    state: { cartState },
    cartDispatch,
  } = useAppContext()
  const isH5 = useMediaQuery('(max-width:750px)')
  const mobile = isMobile()

  //根据这个过滤真正可以交易的数据数据
  const [skipState, setSkipState] = useState({
    pending: true,
    suspicious: true,
  })
  const [isSufficient, setIsSufficient] = useState(true)
  const [pendingMap, setPendingMap] = useState({})
  const timer = useRef(null)
  const { connect } = useConnect()
  const { isConnected } = useAccount()
  const { balance } = useBalance()

  //最终交易的list
  const finallist = useMemo(() => {
    let list = currentCart

    //Skip Pending
    if (skipState.pending) {
      list = list.filter(item => !pendingMap[item.token_id]?.is_pending)
    }

    //Skip Suspicious
    if (skipState.suspicious) {
      list = list.filter(item => !item.opensea_ban)
    }

    cartDispatch({ type: 'updataFinalCart', payload: list })

    return list
  }, [currentCart, skipState, pendingMap])

  //计算总价
  const totalPrice = useMemo(() => {
    return finallist?.reduce(
      (prev, curr) => toNumber(prev) + toNumber(curr.list_price),
      0
    )
  }, [finallist])

  const handleClose = () => {
    cartDispatch({ type: 'visible', payload: false })
  }

  const handleRemove = (item) => {
    const arr = cloneDeep(cartState?.cart)
    remove(arr, el => el.id === item.id)

    cartDispatch({ type: 'update', payload: arr })
    setItem('cart', arr)
  }

  const handleRefresh = () => {
    const arr = filter(currentCart, item => !cartState?.expired?.includes(item.order_id))

    cartDispatch({ type: 'all', payload: { expired: [], cart: arr, loading: false, orders: {}}})
    setItem('cart', arr)
  }

  const handleRemoveAll = () => {
    const currentCartIds = currentCart?.map(item => item.id)
    const arr = cartState.cart?.filter(item => !currentCartIds.includes(item.id))
    cartDispatch({ type: 'all', payload: { expired: [] }})
    cartDispatch({ type: 'update', payload: arr })
    setItem('cart', arr)
  }

  //从接口中获取购物车list
  const fetchCartItems = async () => {
    if (cartState.cart?.length === 0) return
    const orderIds = cartState.cart?.map(el => `order_id=${el.order_id}`).join('&')

    const params = {
      chain: chainId,
      orderIds,
    }
    const ret = await getCartItems(params)

    // 更新item状态信息(和本地购物车对比)
    // 和本地对比（因为可能这个接口数据没返回，已经开始操作clear了）
    let cart = getItem('cart')
    // 更新item状态信息
    if (ret) {
      const result = ret?.data?.result || {}
      const arr = cart?.map((el) => {
        const item = result[el?.order_id] || {}
        return { ...el, ...item }
      })
      // 更新信息
      cartDispatch({ type: 'update', payload: arr })
      setItem('cart', arr)
    }
  }

  // 获取有效的订单，并进行后续流程
  const handlePurchase = async () => {
    const userInfo = getItem('userInfo')
    console.log('finallist', finallist)

    if (isEmpty(userInfo)) {
      cartDispatch({ type: 'visible', payload: false })
    }

    if (!isConnected) {
      connect()
      return
    }

    const isMainNet = await switchToEthereum(mobile)
    if (!isMainNet) return (mobile)

    cartDispatch({ type: 'all', payload: { loading: true, orders: {}}})

    const order_ids = finallist.map(el => el.order_id)

    const params = {
      chain: chainId,
      order_ids,
    }

    //获取这些items对应的在市场上的 listing 订单信息
    const ret = await getValidOrders(params)

    if (ret) {
      const result = ret?.data?.result || {}
      //找出没有在listing的订单id集合
      const invalidOrderIds = finallist?.filter(el => !result[el.order_id])?.map(el => el.order_id)

      cartDispatch({ type: 'all', payload: { expired: invalidOrderIds, orders: result }})

      // 存在有效订单，且过期item为0，才能继续执行下单操作
      if (!isEmpty(result) && invalidOrderIds?.length === 0) {
        cartDispatch({ type: 'all', payload: { open: false, approve: true }})

        if (chainId === 1) {
          confirmAggreBatchPurse(result)
        } else {
          confirmBatchPurse(result)
        }
      }
    }
  }

  // 构造买单信息
  const getBuyOrder = ({
    type, price, date, isChain, tokeAddress, sellAddress, salt,
    itemData, address,
  }) => {
    const collectionAddress = itemData.contract_address
    const nft_id = itemData?.token_id
    let startTime = 0

    if (type === BUY) {
      startTime = 0
    } else {
      startTime = Math.floor(new Date().getTime() / 1000)
    }

    const validity = 60 * 60 * 24 * date
    const endTime = startTime + validity

    const assetType = tokeAddress === ETH_ADDRESS ? ETH : ERC20
    let buyOrder
    if (isChain) {
      // 这里需要将给的nft_id转成数字类型,如果数字太大的话，会导致签名报错
      // const tokeID = Number(nft_id)
      const formatPrice = utils.parseEther(String(price))
      // eslint-disable-next-line max-len
      buyOrder = assOrder(1, address, sellAddress, Asset(ERC721, collectionAddress, nft_id, 1), Asset(assetType, tokeAddress, 0, formatPrice), startTime, date ? endTime : 0, salt)
    } else {
      const formatPrice = Number(price)
      // eslint-disable-next-line max-len
      buyOrder = assOrder(1, address, sellAddress, Asset(ERC721, collectionAddress, nft_id, 1), Asset(assetType, tokeAddress, '0', formatPrice), startTime, date ? endTime : 0, salt)
    }
    return buyOrder
  }

  // 构造匹配订单
  const getMatchOrder = (el, address, orders) => {
    // sell order
    const signature = orders[el?.order_id]?.signature

    let sellOrder = {}
    try {
      sellOrder = JSON.parse(orders[el?.order_id]?.data)
    } catch (error) {

      console.log(error, 'json parse error')
      return
    }

    const price = ethers.utils.parseEther(String(sellOrder.price.value))
    const salt = Math.floor(Math.random() * 10000000)

    const buyOrder = getBuyOrder({
      type: BUY,
      price: sellOrder.price.value,
      date: 0,
      isChain: true,
      tokeAddress: ETH_ADDRESS,
      sellAddress: el?.owner,
      salt,
      itemData: el,
      address,
    })

    sellOrder.price.value = price

    return { sellOrder, buyOrder, signature }
  }

  // 确认购买，链上交互(非聚合交易，针对非以太链)
  const confirmBatchPurse = async (orders) => {
    cartDispatch({ type: 'loading', payload: true })

    const signer = await getSigner(mobile)
    if (!signer) return
    const address = await signer.getAddress()
    if (!address) return

    const batchOrders = currentCart?.map((el) => {
      const ret = getMatchOrder(el, address, orders)
      return ret
    })

    console.info('batchOrder', batchOrders)

    const flag = batchOrders.every(el => !!el)
    if (!flag) {
      cartDispatch({ type: 'all', payload: { loading: false, approve: false }})
      return
    }

    const exchageInstance = await CONTRACT.getExchangeInstanceBatch()
    if (!exchageInstance) return

    const price = ethers.utils.parseEther(String(numFormate(totalPrice)))

    const estimatedGas = executeContractMethodWithEstimatedGas.bind(exchageInstance)
    const [err, result] = await errorCaptured(estimatedGas, exchageInstance, 'batchMatchTry', [
      batchOrders,
      {
        value: price,
      },
    ])

    if (err) {
      showSnackBar({ type: 'error', message: 'You rejected the request in your wallet.' })
      cartDispatch({ type: 'all', payload: { loading: false, approve: false }})
      return
    }

    if (result) {
      // setBuyStatus('buyWaitChain')
      let [waitErr, receipt] = await errorCaptured(result.wait)
      if (waitErr) {
        showSnackBar({ type: 'error', message: 'Transaction failed. Please try again.' })
        // setOpen(false)
        return
      }
      if (receipt) {
        if (receipt?.status === 1) {
          setTimeout(() => {
            // setBuyStatus('buySuccess')

            showSnackBar({ type: 'success', message: 'You have successfully purchased.' })

            console.info('receipt', receipt)

            let success = [], fail = []

            currentCart?.forEach((el, index) => {
              if (receipt?.events[index]?.event === 'BatchMatchInnerError') {
                fail.push(el)
              } else {
                success.push(el)
              }
            })

            cartDispatch({
              type: 'all',
              payload: { approve: false, feedback: true, cart: [], success, fail },
            })
            setItem('cart', [])

          }, 5000)
        } else if (receipt?.status === 0) {
          showSnackBar({ type: 'error', message: 'Transaction failed. Please try again.' })
          // setBuyStatus('buyFailed')
          cartDispatch({ type: 'all', payload: { approve: false }})
        }
      }
    }
  }

  // 确认购买，链上交互（以太链走聚合交易）
  const confirmAggreBatchPurse = async (validOrders) => {
    cartDispatch({ type: 'loading', payload: true })

    const signer = await getSigner(mobile)
    if (!signer) return
    const address = await signer.getAddress()
    if (!address) return

    //构造匹配订单 （TODO，走新的聚合交易逻辑）
    const executeData = await executeSwap({
      buyAddress: address,
      ordersList: Object.values(validOrders || []),
    })
    //拿到匹配的订单数据，交给合约
    console.info('executeData', executeData)

    const { commands, inputs, totalValue, DEADLINE } = executeData || {}
    const router = await CONTRACT.get_Trade_Instance()
    const estimatedGas = executeContractMethodWithEstimatedGas.bind(router)
    const [err, result] = await errorCaptured(estimatedGas, router, 'execute(bytes,bytes[],uint256)', [
      commands,
      inputs,
      DEADLINE,
      {
        value: totalValue,
      },
    ])

    if (err) {
      showSnackBar({ type: 'error', message: 'You rejected the request in your wallet.' })
      cartDispatch({ type: 'all', payload: { loading: false, approve: false }})
      return
    }

    if (result) {
      // setBuyStatus('buyWaitChain')
      let [waitErr, receipt] = await errorCaptured(result.wait)
      if (waitErr) {
        showSnackBar({ type: 'error', message: 'Transaction failed. Please try again.' })
        // setOpen(false)
        return
      }
      if (receipt) {
        if (receipt?.status === 1) {
          setTimeout(() => {
            // setBuyStatus('buySuccess')

            showSnackBar({ type: 'success', message: 'You have successfully purchased.' })

            console.info('receipt', receipt)

            let success = [], fail = []

            currentCart?.forEach((el, index) => {
              if (receipt?.events[index]?.event === 'BatchMatchInnerError') {
                fail.push(el)
              } else {
                success.push(el)
              }
            })

            cartDispatch({
              type: 'all',
              payload: { approve: false, feedback: true, cart: [], success, fail },
            })
            setItem('cart', [])

          }, 5000)
        } else if (receipt?.status === 0) {
          showSnackBar({ type: 'error', message: 'Transaction failed. Please try again.' })
          // setBuyStatus('buyFailed')
          cartDispatch({ type: 'all', payload: { approve: false }})
        }
      }
    }
  }

  useEffect(() => {
    if (cartState?.open) {
      fetchCartItems()
    }
  }, [cartState?.open])

  const handleChange = (e) => {
    setSkipState({
      ...skipState,
      [e.target.name]: e.target.checked,
    })
  }

  //登录之后判余额
  useEffect(() => {
    const checkBalance = async(address) => {
      //余额是否充足
      setIsSufficient(Number(totalPrice) < Number(balance))
    }

    const userInfo = getItem('userInfo')
    if (userInfo?.user?.address) {
      checkBalance(userInfo?.user?.address)
    }
  }, [totalPrice])

  // 秒轮询pending接口，返回tokenids是否在pending的map(只有chain=1才有)
  useEffect(() => {
    const loop = async() => {
      const params = {
        items: currentCart.map((item) => {
          return {
            address: item.contract_address,
            token_id: item.token_id,
          }
        }),
      }
      const res = await getPendingTxs(params)
      const data = res?.data?.result || []

      const map = {}
      data.forEach((item) => {
        map[item.token_id] = {
          ...item,
        }
      })
      setPendingMap(map)

      timer.current = setTimeout(loop, 4000)
    }

    if (cartState?.open && currentCart?.length > 0 && chainId === 1) {
      loop()
    }

    if (!cartState?.open) {
      clearTimeout(timer.current)
    }

    return () => {
      clearTimeout(timer.current)
    }
  }, [currentCart, cartState?.open, chainId])

  return (
    <>
      {!isH5 ? (
        <Dialog
          open={cartState?.open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-cart"
          sx={{
            '& .MuiDialog-container': {
              justifyContent: 'flex-end',
              '& .MuiPaper-root': {
                width: '100%',
                maxWidth: '375px',
                minHeight: 'calc(100vh - 50px)',
                margin: '25px',
                borderRadius: '10px',
              },
            },
          }}
        >
          <DialogTitle sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            py: '24px',
            fontSize: '20px',
            lineHeight: '30px',
          }}
          >
            {'Your cart '}{currentCart?.length ? `(${currentCart.length})` : ''}
            <CloseIcon sx={{ cursor: 'pointer' }} onClick={handleClose} />
          </DialogTitle>
          <DialogContent dividers="paper" sx={{ padding: '25px 0 0' }}>
            <DialogContentText id="alert-dialog-slide-description">
              {!currentCart?.length
                ? (
                  <Box className={styles.empty}>
                    <Typography>
                      Add items to get started.
                    </Typography>
                  </Box>
                )
                : (
                  <>
                    <Box className={styles.title}>
                      <Typography
                        variant="h1"
                        component="h1"
                      >
                        Your cart
                      </Typography>
                      <Button
                        variant="text"
                        disableElevation
                        disableRipple
                        onClick={handleRemoveAll}
                      >Clear all
                      </Button>
                    </Box>

                    <Box className={styles.itemContainer}>
                      {currentCart?.map(item => (
                        <Box key={item.id}
                          className={classNames(styles.item, (
                            (skipState.pending && pendingMap[item.token_id]?.is_pending) ||
                            (item.opensea_ban && skipState.suspicious ) ? styles.opacity_06 : ''))}
                        >
                          <Box className={styles.imgWrapper}>
                            <img src={item.oss_uri} alt="item" onError={imgOnError}/>
                          </Box>

                          <Box className={`${styles.infoWrapper}  ${cartState?.expired?.includes(item.order_id) ? styles.expired : ''}`}>
                            <Box className={styles.info}>
                              <span className={styles.token_id_wrap}>
                                <Typography
                                  variant="p"
                                  component="p"
                                  className={styles.tokenId}
                                >
                                  #{item.token_id}
                                </Typography>
                                {
                                  item.opensea_ban && (
                                    <Tooltip title="This item can not be sold on OpenSea." placement="top">
                                      <span className={styles.errorImg}>
                                        <OpenseaBan/>
                                      </span>
                                    </Tooltip>
                                  )
                                }
                              </span>

                              <Typography
                                variant="p"
                                component="p"
                                className={styles.name}
                              >
                                {item.collection_name}
                              </Typography>

                              {
                                (skipState.pending && pendingMap[item.token_id]?.is_pending) ||
                               (item.opensea_ban && skipState.suspicious ) && (
                                 <Typography variant="div" component="div" className={styles.changed}>
                                   <InfoOutlinedIcon />
                                   Item will be skipped
                                 </Typography>
                               )
                              }

                              {cartState?.expired?.includes(item.order_id) && (
                                <Typography variant="div" component="div" className={styles.changed}>
                                  <InfoOutlinedIcon />
                                  Changed
                                </Typography>
                              )}
                            </Box>
                            <Box className={styles.price}>
                              {pendingMap[item.token_id]?.is_pending && (
                                <div className={styles.iconWrap}>
                                  <CachedIcon className={classNames(styles.cachLoading, styles.rotateAniamtion)}/>
                                </div>
                              )}

                              <Price
                                chainId={item.chainId}
                                width={16} height={16}
                                price={item?.list_price}
                                showDollar={chainId !== 1}
                                sx={{ textAlign: 'right', fontSize: '16px', fontWeight: 600, lineHeight: '24px' }}
                                dollarSx={{ textAlign: 'right' }}
                              />
                            </Box>
                            <Box className={styles.remove}>
                              <DeleteOutlineIcon onClick={() => handleRemove(item)}/>
                            </Box>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </>
                )
              }
              <Box className={styles.footer}>
                {currentCart?.length > 0 && (
                  <Box className={styles.total}>
                    <Typography variant="p" component="p">Total price</Typography>
                    <Price
                      width={16} height={16}
                      price={totalPrice}
                      showDollar={true}
                      sx={{ fontSize: '16px', fontWeight: 600, lineHeight: '24px' }}
                      dollarSx={{ textAlign: 'right' }}
                    />
                  </Box>
                )}

                {(currentCart?.length > 0 && chainId === 1) && (
                  <Box className={styles.skipBox}>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox name="pending"
                            sx={{
                              color: '#e5e8eb',
                              padding: '6px',
                            }}
                            checked={skipState?.pending}
                            onChange={handleChange}
                          />
                        }
                        label="Skip Pending"
                        sx={{
                          marginRight: '30px',
                          marginLeft: 0,
                          borderRadius: '10px',
                          '& .MuiTypography-root': { fontSize: '14px' },
                        }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox name="suspicious"
                            sx={{
                              color: '#e5e8eb',
                              padding: '6px',
                            }}
                            checked={skipState?.suspicious}
                            onChange={handleChange}
                          />
                        }
                        label="Skip Suspicious"
                        sx={{
                          marginRight: 0,
                          marginLeft: 0,
                          borderRadius: '10px',
                          '& .MuiTypography-root': { fontSize: '14px' },
                        }}
                      />
                    </FormGroup>
                  </Box>
                )
                }

                {cartState?.expired?.length > 0 ? (
                  <Button
                    fullWidth
                    variant="outlined"
                    onClick={handleRefresh}
                    sx={{
                      fontSize: '16px',
                      fontWeight: 600,
                      height: '60px',
                      marginTop: '4px',
                      borderRadius: '10px',
                      backgroundColor: '#fff',
                      color: '#5856d6',
                      borderColor: '#5856d6',
                    }}
                  >
                    Refresh
                  </Button>
                ) : (
                  <Button
                    fullWidth
                    variant="contained"
                    disabled={cartState?.loading || !currentCart?.length || !isSufficient || finallist?.length === 0}
                    onClick={handlePurchase}
                    sx={{
                      fontSize: '16px',
                      fontWeight: 600,
                      height: '60px',
                      marginTop: '4px',
                      borderRadius: '10px',
                      backgroundColor: '#5856d6',
                      '&.Mui-disabled': {
                        opacity: '0.2',
                        color: '#fff !important',
                        backgroundColor: '#5856d6 !important',
                      },
                    }}
                  > { !isSufficient ? 'Insufficient Balance' : 'Complete purchase'}
                  </Button>
                )}

              </Box>

            </DialogContentText>
          </DialogContent>
        </Dialog>
      ) : (
        <Drawer
          anchor="bottom"
          open={cartState?.open}
          onClose={handleClose}
          sx={{
            '.MuiPaper-root': {
              borderTopLeftRadius: 16,
              borderTopRightRadius: 16,
            },
          }}
        >
          <Box className={styles.drawer}>
            <Typography
              variant="h1"
              component="h1"
              className={styles.title}
            >
              {'Your cart '}{currentCart?.length ? `(${currentCart.length})` : ''}
              <CloseIcon onClick={handleClose} />
            </Typography>

            <Box>
              {!currentCart?.length ? (
                <Box className={styles.empty}>
                  <Typography>
                    Add items to get started.
                  </Typography>
                </Box>
              ) : (
                <Box className={styles.itemWrapper}>
                  <Button
                    variant="text"
                    disableElevation
                    disableRipple
                    onClick={handleRemoveAll}
                  >Clear all
                  </Button>
                  <Box className={styles.itemContainer}>
                    {currentCart?.map(item => (
                      <Box key={item.id}
                        className={classNames(styles.item, (
                          (skipState.pending && pendingMap[item.token_id]?.is_pending) ||
                          (item.opensea_ban && skipState.suspicious ) ? styles.opacity_06 : ''))}
                      >
                        <Box className={styles.imgWrapper}>
                          <img src={item.oss_uri} alt="item" onError={imgOnError}/>
                        </Box>
                        <Box className={styles.info}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography
                              variant="p"
                              component="p"
                              className={styles.tokenId}
                            >
                              #{item.token_id}
                            </Typography>
                            {
                              item.opensea_ban && (
                                <Tooltip title="This item can not be sold on OpenSea." placement="top">
                                  <span className={styles.errorImg} style={{ marginLeft: 4 }}>
                                    <OpenseaBan/>
                                  </span>
                                </Tooltip>
                              )
                            }

                            {pendingMap[item.token_id]?.is_pending && (
                              <div style={{ marginLeft: 4 }}>
                                <CachedIcon className={classNames(styles.cachLoading, styles.rotateAniamtion)}/>
                              </div>
                            )}
                          </div>

                          <Typography
                            variant="p"
                            component="p"
                            className={styles.name}
                          >
                            {item.collection_name}
                          </Typography>
                          <Price
                            chainId={item.chainId}
                            width={16} height={16}
                            price={item?.list_price}
                            showDollar={true}
                            sx={{ textAlign: 'right', fontSize: '16px', fontWeight: 600, lineHeight: '24px' }}
                            dollarSx={{ paddingLeft: '10px', textAlign: 'left' }}
                          />
                        </Box>
                        <Box>
                          <DeleteOutlineIcon onClick={() => handleRemove(item)}/>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Box>
              )}
            </Box>
            <Box className={styles.footer}>
              {currentCart?.length > 0 && (
                <Box className={styles.total}>
                  <Typography variant="p" component="p">Total price</Typography>
                  <Price
                    width={16} height={16}
                    price={totalPrice}
                    showDollar={true}
                    sx={{ fontSize: '16px', fontWeight: 600, lineHeight: '24px' }}
                    dollarSx={{ textAlign: 'right' }}
                  />
                </Box>

              )}

              {(currentCart?.length > 0 && chainId === 1) && (
                <Box className={styles.skipBox}>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox name="pending"
                          sx={{
                            color: '#e5e8eb',
                            padding: '6px',
                          }}
                          checked={skipState?.pending}
                          onChange={handleChange}
                        />
                      }
                      label="Skip Pending"
                      sx={{
                        marginRight: '30px',
                        marginLeft: '16px',
                        borderRadius: '10px',
                        '& .MuiTypography-root': { fontSize: '14px' },
                      }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox name="suspicious"
                          sx={{
                            color: '#e5e8eb',
                            padding: '6px',
                          }}
                          checked={skipState?.suspicious}
                          onChange={handleChange}
                        />
                      }
                      label="Skip Suspicious"
                      sx={{
                        marginRight: 0,
                        marginLeft: 0,
                        borderRadius: '10px',
                        '& .MuiTypography-root': { fontSize: '14px' },
                      }}
                    />
                  </FormGroup>
                </Box>
              )}

              <Box className={styles.btnWrapper}>
                {cartState?.expired?.length > 0 ? (
                  <Button
                    fullWidth
                    variant="outlined"
                    onClick={handleRefresh}
                    sx={{
                      fontSize: '16px',
                      fontWeight: 600,
                      height: '60px',
                      marginTop: '4px',
                      borderRadius: '10px',
                      backgroundColor: '#fff',
                      color: '#5856d6',
                      borderColor: '#5856d6',
                    }}
                  >
                    Refresh
                  </Button>
                ) : (
                  <Button
                    fullWidth
                    variant="contained"
                    disabled={cartState?.loading || !currentCart?.length || !isSufficient || finallist?.length === 0}
                    onClick={handlePurchase}
                    sx={{
                      fontSize: '16px',
                      fontWeight: 600,
                      height: '60px',
                      marginTop: '4px',
                      borderRadius: '10px',
                      backgroundColor: '#5856d6',
                      '&.Mui-disabled': {
                        opacity: '0.2',
                        color: '#fff !important',
                        backgroundColor: '#5856d6 !important',
                      },
                    }}
                  >{ !isSufficient ? 'Insufficient Balance' : 'Complete purchase'}
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        </Drawer>
      )}
    </>

  )
}

export default Cart