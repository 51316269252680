import React, { useState, useRef, useEffect, useMemo } from 'react'
import classNames from 'classnames'
import isMobile from 'is-mobile'
import { Fade } from '@mui/material'
import styles from '@/assets/Header.module.scss'
import { useDebounce } from '@/utils/customHooks'
import { getSearchList } from '@/api'
import Loading from '@components/Loading'

import SearchItem from './SearchItem.jsx'

const searchResultDefault = { collection: [], items: [], users: [], projects: [] }

export default function SearchBar({
  tabValue,
  isPhone,
  onClose,
}) {
  const [show, setShow] = useState(false)
  const [isSearchInputFoucs, setIsSearchInputFoucs] = useState(false)
  const [searchResult, setSearchResult] = useState(searchResultDefault)
  const [isSearchLoading, setIsSearchLoading] = useState(false)

  const inputRef = useRef()

  const handleFoucsSearch = (e) => {
    e.stopPropagation()
    setIsSearchInputFoucs(true)
  }

  useEffect(() => {
    if (inputRef.current) inputRef.current.value = ''
    setShow(false)
  }, [tabValue])

  const handleSearch = async (e) => {
    const { value } = e.target
    if (value) {
      setShow(true)
      setIsSearchLoading(true)
      setSearchResult(searchResultDefault)
      const result = await getSearchList({ name: value.replace(/(^\s*)|(\s*$)/g, '') })
      if (!inputRef.current) return
      if (result && result.code === 200) {
        const { collection, items, users, projects } = result.data || {}
        const data = {
          collection: collection.result || [],
          items: items.result || [],
          users: users.result || [],
          projects: projects.result || [],
        }
        setSearchResult(data)
        setIsSearchLoading(false)
      }
    } else {
      setShow(false)
    }
  }

  const handleDebounceSearch = useDebounce(handleSearch, 500)

  const documentClickEvent = () => {
    setIsSearchInputFoucs(false)
    if (!isPhone) {
      setSearchResult(searchResultDefault)
      setShow(false)
    }
  }

  const hideKeyboard = (e) => {
    const { keyCode } = e
    keyCode === 13 && isMobile() && document.activeElement.blur()
  }

  const handleCloseSearch = () => {
    if (inputRef.current) inputRef.current.value = ''
    setShow(false)
    setSearchResult(searchResultDefault)
  }

  const handleHideSearchArea = () => {
    onClose(false)
    setShow(false)
  }

  useEffect(() => {
    document.body.addEventListener('click', documentClickEvent, false)
    inputRef.current?.addEventListener('keydown', hideKeyboard, false)
    return () => {
      document.body.removeEventListener('click', documentClickEvent)
      inputRef.current?.removeEventListener('keydown', hideKeyboard)
    }
  }, [])

  const searchResultEle = useMemo(() => {
    return (
      <div className={styles.search_result}>
        {
          isSearchLoading && (
            <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
              <Loading
                boxStyle={{ width: '24px', height: '24px' }} circleStyle={{ background: '#ddd' }}
              />
            </div>
          )
        }
        {searchResult.collection.length ? (
          <SearchItem
            title="Collections"
            data={searchResult.collection}
            imgUrlKey="oss_uri"
          />
        ) : null}
        {searchResult.items.length ? (
          <SearchItem
            title="Items"
            data={searchResult.items}
            imgUrlKey="oss_uri"
          />
        ) : null}
        {searchResult.projects.length ? (
          <SearchItem
            title="Launchpad"
            data={searchResult.projects}
            imgUrlKey="meta_file_uri"
          />
        ) : null}
        {searchResult.users.length ? (
          <SearchItem
            title="Accounts"
            data={searchResult.users}
            imgUrlKey="avatar_uri"
          />
        ) : null}

        {show && (
          !searchResult.length &&
            !searchResult.collection.length &&
            !searchResult.items.length &&
            !searchResult.users.length
        ) && !isSearchLoading
          ? (
            <div>
              <div className={styles.search_title}>
                <span>No items found</span>
              </div>
            </div>
          ) : null}
      </div>
    )
  }, [
    isSearchLoading,
    searchResult,
    show,
  ])

  if (isPhone) {
    return (
      <>
        <nav className={styles.header_box}>
          <i className={styles.left_arrow} onClick={handleHideSearchArea}/>
          <input ref={inputRef} type="text" placeholder="Search Nswap" maxLength="200"onChange={handleDebounceSearch} />
          {show && <i className={styles.search_close} onClick={handleCloseSearch} />}
        </nav>
        <div className={styles.mobile_search_result} >
          {show && searchResultEle}
        </div>
      </>
    )
  }

  return (
    <div className={classNames(styles.search_area, isSearchInputFoucs ? styles.foucs : '')} onClick={handleFoucsSearch}>
      <i className={styles.search_icon}/>
      <input
        className={styles.search_area_input}
        ref={inputRef}
        type="text"
        placeholder="Search for collections, launchpad, or users"
        maxLength="200"
        onChange={handleDebounceSearch}
      />
      {show && <i className={styles.search_close_icon} onClick={handleCloseSearch}/>}
      <Fade in={show} timeout={200} sx={{ position: 'absolute', zIndex: 2 }}>
        {searchResultEle}
      </Fade>
    </div>
  )
}
