import * as ethers from 'ethers'

import NSWAP_ABI from './abi/Nswap.json'

const ZERO = '0x0000000000000000000000000000000000000000'

export const nswapInterface = new ethers.utils.Interface(NSWAP_ABI)

export function createNswapOrders(
  apiOrder,
  taker,
  signature
) {
  let makerOrder
  let takerOrder
  makerOrder = {
    side: 0,
    saleKind: 0,
    maker: apiOrder.maker,
    taker: ZERO,
    nft: apiOrder.nft,
    price: apiOrder.price,
    salt: apiOrder.salt,
    start: apiOrder.start,
    end: apiOrder.end,
    version: apiOrder.version,
    extra: apiOrder.extra,
  }
  takerOrder = {
    side: 1,
    saleKind: 0,
    maker: taker,
    taker: ZERO,
    nft: apiOrder.nft,
    price: apiOrder.price,
    salt: apiOrder.salt,
    start: apiOrder.start,
    end: apiOrder.end,
    version: apiOrder.version,
    extra: apiOrder.extra,
  }
  const value = ethers.utils.parseEther(apiOrder.price?.value?.toString())

  makerOrder.price.value = value
  makerOrder.price.value = value
  return { makerOrder, takerOrder, signature, value }
}