import React, { useEffect } from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import Header from '../components/Header/index.jsx'
import Footer from '../components/Footer.jsx'
import FooterView from '@components/Footer/Footer.jsx'
import Script from 'next/script'
import useDollar from '@/hooks/useDollar.js'
import CheckChainModal from '@components/CheckChainModal'
import { useSelector } from 'react-redux'
import { usePhone } from '@utils/utils'

const hideFooterRouter = [
  '/market',
  '/user/[[...address]]',
  '/collection/[chainName]/[contract_address]',
  '/raffles/[raffle_detail]',
  '/year2022',
  '/year2022/[...chain]',
  '/listings',
]

const changeBgColor = [
  '/raffles/manage/[raffle_id]',
]

export default function Layouts(props) {
  const router = useRouter()
  const { visitChainId } = useSelector(state => state.chain)
  const isPhone = usePhone()
  // 获取链对应的单价
  useDollar(visitChainId)

  useEffect(() => {
    const customBg = () => {
      if (typeof window !== 'undefined') {
        return changeBgColor.includes(router.pathname)
      }
      return false
    }
    const el = document.getElementsByClassName('content')[0]
    if (customBg()) {
      el.style.backgroundColor = '#F8F8F8'
    } else {
      el.removeAttribute('style', '')
    }
  }, [router])

  const rem = () => {
    if (typeof window !== 'undefined') {
      const doc = window.document
      const docEl = doc.documentElement
      const width = docEl.getBoundingClientRect().width
      if (width < 750) {
        const rem = width / 375
        docEl.style.fontSize = `${rem}px`
      }
    }
  }

  const hideFooter = () => {
    if (typeof window !== 'undefined') {
      return hideFooterRouter.includes(router.pathname)
    }
    return false
  }

  return (
    <>
      <Script strategy="beforeInteractive" id="rem">{rem()}</Script>
      <script src="//cdn.jsdelivr.net/npm/eruda" />
      <script>console.log(window.location.href);if(/debug=1/.test(window.location)) eruda.init();</script>
      <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&amp;display=swap" />
      <Head>
        <title>NSWAP - The space for metaverse NFTs</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
        <meta name="keywords" content="NFT,Nswap,NFT launchpad,metaverse NFT,NFT marketpalce,NFT ranking,NFT analytics,NFT data" />
        <meta name="title" property="og:title" content="Nswap：The space for metaverse NFTs" />
        <meta name="description" content="Spotting NFT by ranking, rarity, launchpad and more! The best all-in-one NFT platform." />
        <meta name="theme-color" content="#ffffff" />
        <link rel="shortcut icon" href="https://s3-nswap-base.nswap.com/0.3.3/ns-bright-loog.png" />
      </Head>
      <Header />
      <div className="content">
        {props.children}
      </div>
      {!hideFooter() && <FooterView />}
      <CheckChainModal/>
    </>
  )
}
