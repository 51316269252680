const { utils } = require("ethers")

function id(str) {
  return utils.keccak256(utils.toUtf8Bytes(str)).substring(0, 10)
}

const ETH = id("ETH") // 0xaaaebeba
const ERC20 = id("ERC20") // 0x8ae85d84
const ERC721 = id("ERC721") // 0x73ad2146
const ERC1155 = id("ERC1155") // 0x973bb640
const CRYPTO_PUNKS = id("CRYPTO_PUNKS") // 0x3e6b89d4
const ORDER_DATA = id("V1") // 0x4c234266

export {
  id,
  ETH,
  ERC20,
  ERC721,
  ERC1155,
  CRYPTO_PUNKS,
  ORDER_DATA,
}