import * as Wagmi from 'wagmi'
import { useAccount, useNetwork } from 'wagmi'

//返回wagmi提供的账户信息
export default function useBalance() {
  const { address } = useAccount()
  const { chain } = useNetwork()

  const result = Wagmi.useBalance({
    address,
    chainId: chain?.id,
  })

  const data = result?.data || {}
  const balance = data?.formatted

  return { balance, ...data }
}
