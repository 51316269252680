import { ethers } from 'ethers';
import { BADGE_ADDRESS } from '../Address';
import BADGE_ABI from '../ABI/BADGE.json';
import getSigner from './getSigner.js';


const getBadgeInstance = async () => {
  const signer = await getSigner()
  if (!signer) return
  return new ethers.Contract(BADGE_ADDRESS, BADGE_ABI, signer);
}

export default getBadgeInstance;