import React from 'react'
import styles from '@assets/Loading.module.css'

const Loading = ({ boxStyle, circleStyle }) => {
  return (
    <div className={styles.loadEffect} style={boxStyle}>
      <span style={circleStyle} />
      <span style={circleStyle} />
      <span style={circleStyle} />
      <span style={circleStyle} />
      <span style={circleStyle} />
      <span style={circleStyle} />
      <span style={circleStyle} />
      <span style={circleStyle} />
    </div>
  )
}

export default Loading