import { useEffect } from 'react'
import { setItem } from '@/utils/utils'
import { getDollar } from '@/api'

const useDollar = (chainId) => {

  const fetchDollarRate = async (chainId) => {
    let params = {}
    if (chainId === 56) {
      params = {
        currency: 'bsc',
      }
    }

    const ret = await getDollar({
      ...params,
      chainId,
    })
    const { data } = ret || {}
    setItem('rate', data)
  }

  useEffect(() => {
    // ipo 链不需要获取价格
    if (chainId && chainId !== 101) {
      fetchDollarRate(chainId)
    }
  }, [chainId])
}

export default useDollar