import { ethers } from 'ethers';
import NFT_ABI from '../ABI/NFT.json';
import getSigner from './getSigner.js';

// 使用以下方式的abi也可以调用合约对应的方法
// const abi = [
//     // Read-Only Functions
//     "function balanceOf(address owner) view returns (uint256)",
//     "function decimals() view returns (uint8)",
//     "function symbol() view returns (string)",

//     // Authenticated Functions
//     "function transfer(address to, uint amount) returns (bool)",

//     // Events
//     "event Transfer(address indexed from, address indexed to, uint amount)"
// ];

const getMobileNft = async (address) => {
    const signer = await getSigner(true)
    if (!signer) return
    return new ethers.Contract(address, NFT_ABI, signer);
}
export default getMobileNft;
