import { ethers } from 'ethers';
import getSigner from './getSigner.js';
import getDefaultProvider from './getDefaultProvider';
import ABI from '../ABI'
import { MINT_ADDRESS_MAP } from '@contract/Address'

const getMintInstance = async (address,readOnly = false) => {
    const provider = await getDefaultProvider()
    let address_abi = ABI[MINT_ADDRESS_MAP[address]]

    try {
        const instance = new ethers.Contract(address, address_abi, readOnly ? provider : await getSigner(true));
        return instance
    } catch (e) {
        console.log(e, 'instance error')
        return;
    }
}
export default getMintInstance;
