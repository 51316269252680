import { ethers } from 'ethers';
import { BSC_ROBOT_MAIN_ADDRESS } from '../Address.js'
import RobotABI from '../ABI/Robot.json'
import getSigner from './getSigner.js';
import { isProduction } from '@utils/utils'
import getDefaultProvider from './getDefaultProvider';


const getRobotInstance = async (readOnly = false) => {
    const provider = await getDefaultProvider()
    const address = isProduction() ? BSC_ROBOT_MAIN_ADDRESS : BSC_ROBOT_MAIN_ADDRESS
    if (!readOnly) {
        const signer = await getSigner()
        if (!signer) return
        return new ethers.Contract(address, RobotABI, signer);
    }
    return new ethers.Contract(address, RobotABI, provider)
}
export default getRobotInstance;
