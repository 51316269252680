import React, { useState } from 'react'
import styles from './Footer.module.scss'
import ComImage from '@components/ComImage'
import { TOP_LOGO } from '@utils/img'
import Router from 'next/router'
const dataList = [
  {
    name: 'Products',
    list: [
      {
        text: 'Launchpad',
        path: '/launchpad',
        link: '',
      },
      {
        text: 'Lucky',
        link: '',
        path: '/raffles',
      },
      {
        text: 'Top',
        link: '',
      },
      {
        text: 'Trending',
        link: '',
      },
      {
        text: 'Lucci Factory',
        path: '/robot',
        link: '',
      },
      {
        text: 'Snali Factory',
        path: '/snaili',
        link: '',
      },
    ],
  },
  {
    name: 'Learn',
    list: [
      {
        text: 'What is Nswap',
        link: 'https://medium.com/nswap/what-is-nswap-92cb35fb830',
      },
      {
        text: 'How to buy NFTs',
        link: 'https://medium.com/nswap/how-to-buy-an-nft-on-nswap-b2fa245a56ea',
      },
      {
        text: 'How to create lucky',
        link: 'https://medium.com/nswap/how-to-create-a-free-nft-raffle-on-nswap-404dd69f3e41',
      },
    ],
  },
  {
    name: 'Community',
    list: [
      {
        text: 'Twitter',
        link: '',
      },
      {
        text: 'Discord',
        link: '',
      },
      {
        text: 'Medium',
        link: '',
      },
      {
        text: 'Telegram',
        link: '',
      },
      {
        text: 'Instagram',
        link: '',
      },
      {
        text: 'Youtube',
        link: '',
      },
    ],
  },
]

const Footer = () => {

  const goDetail = (item) => {
    const { path, link } = item
    console.log(path, link, 'path link')
    if (path) {
      Router.push(path)
      return
    }
    if (link) {
      window.open(link)
    }
  }

  return (
    <footer className={styles.footer_container}>
      <div className={styles.footer_box}>
        <div className={styles.footer_up}>
          <div className={styles.left_box}>
            <ComImage src={TOP_LOGO} width={102} height={54} />
            <div className={styles.left_desc}>
              Resources for getting startedResources for getting startedResources for getting started
            </div>
          </div>
          <div className={styles.right_box}>
            <div className={styles.guide_box}>
              {dataList.map((item) => {
                return (
                  <div className={styles.guide_item} key={item.name}>
                    <div className={styles.guideTitle}>{item.name}</div>
                    {item.list.map((arr, index) => {
                      return (
                        <div className={styles.arrItem} key={index} onClick={() => goDetail(arr)}>
                          {arr.text}
                        </div>
                      )
                    })}
                  </div>
                )
              })}

              {/* <div className={styles.qrcode_box}>
                <div className={styles.guideTitle}>
                  Download NSWAP app
                </div>
                <div className={styles.qrcodeImgBox}>
                  img1
                </div>
                <div className={styles.qrcodeImgBox}>
                  img2
                </div>

              </div> */}
            </div>

          </div>
        </div>
        <div className={styles.footer_down}>
          <div className={styles.down_text}>
            2018 - 2022 Ozone Networks, Inc
          </div>
          <div className={styles.down_text}>
            Privacy Policy
          </div>
        </div>
      </div>

    </footer>
  )
}

export default Footer
